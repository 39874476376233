@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';
@import '../../styles/scss/sharedStyles';
.issueDashboardContainer {
  background-color: $color-background-container;
  border-radius: $border-radius-3;
  width: 100%;
  box-shadow: $box-shadow-1;
  padding: $spacing-6;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  margin-top: $spacing-5;
  cursor: pointer;
  .isdRadioArea {
    width: 4%;
    padding-right: $spacing-6;

    span {
      width: 100%;
      height: 100%;
    }
    .openRadioColor {
      color: $states-success-04;
    }
    .closeRadioColor {
      color: $color-background-icon-primary;
    }

    .closedCheckmarkColor {
      color: $data-purple;
    }
  }
  .sectionWrapper-container{
    display: flex;
    flex-direction: column;
    width: calc(96% - rem(40px));
    .textArea.badge-wrapper {
      .ap-badge{
        gap: rem(6px);
        padding-left:rem(4px);
        padding-right:rem(4px);
        &.ap-badge-info{
          background-color: $color-background-hover-selected;
          color: $color-text-body;
          border-radius: rem(4px);
          height: rem(20px);
          .Appkit4-icon{
            width:rem(12px);
            line-height: rem(12px);
            height: rem(12px);
          }
        }
      }
      .ap-badge span {
        height: rem(16px);
        font-weight: var(--font-weight-1);
      }
    }
    .sectionWrapper{
    display: flex;
    width: 100%;
    .isdTextArea {
      width: 65%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: rem(20px);
      .textArea {
        width: 100%;

        margin: 0 $spacing-6 $spacing-2 0;
        // margin: 0 $spacing-6 $spacing-4 0;
        display: flex;
        .issueLabel {
          width: 100%;

          font-weight: $font-weight-2;
          font-size: $spacing-6;
          line-height: rem(24px);
          letter-spacing: rem(-0.4px);
          color: $color-text-body;
          // margin-right: $spacing-4;
        }
      }
      .imgArea {
        margin: 0 0 $spacing-2 0;//$spacing-6
        // margin: 0 $spacing-6 $spacing-4 0;
        font-weight: $font-weight-1;
        font-size: rem(16px);
        line-height: rem(24px);
        letter-spacing: rem(-0.4px);
        color: $color-text-body;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        .no-description {
          color: $color-text-light;
        }
      }
      .acNumberArea {
        margin: 0 $spacing-6 0 0;
        // margin: 0 $spacing-6 0 0;
        color: $color-text-light;
        font-weight: $font-weight-1;
        font-size: rem(14px);
        line-height: $spacing-6;
        letter-spacing: rem(-0.2px);
      }
    }
    .tagPart {
      width: 30%;
      flex-wrap: wrap;
      height: rem(25px);

      display: flex;
      gap: rem(4px);
        .tagSingle {
          margin-right: $spacing-2;
          margin-top: $spacing-2;

          .ap-badge-text {
            text-wrap: nowrap;
          }

          .issue-info-header-type-general {
            color: $primary-blue-04;
            box-shadow: 0 0 0 rem(1px) $primary-blue-04;
            background-color: rgba($primary-blue-04, var(--opacity-3));
          }

          .issue-info-header-type-bug {
            color: $data-blue;
            box-shadow: 0 0 0 1px $data-blue;
          }

          .issue-info-header-type-access {
            color: $data-blue;
            box-shadow: 0 0 0 1px $data-blue;
            background-color: rgba($data-blue, var(--opacity-3));
          }

          .issue-info-header-type-feature {
            color: $data-green;
            box-shadow: 0 0 0 1px $data-green;
            background-color: rgba($data-green, var(--opacity-3));
          }

          .issue-info-header-type-component {
            color: $data-purple;
            box-shadow: 0 0 0 1px $data-purple;
            background-color: rgba($data-purple, var(--opacity-3));
          }

          .issue-info-header-type-contribution {
            color: $data-gray-dark;
            box-shadow: 0 0 0 1px $data-gray-dark;
            // background-color: rgba($data-gray-dark, var(--opacity-3));
          }

          .issue-info-header-type-installation {
            color: $neutral-10;
            box-shadow: 0 0 0 1px $neutral-10;
            background-color: rgba($neutral-10, var(--opacity-3));
          }

          .issue-info-header-type-design {
            color: #000000;
            box-shadow: 0 0 0 1px $data-tangerine-light;
            background-color: rgba($data-tangerine-light, var(--opacity-3));
          }
          .issue-info-header-type-danger {
            color: #c52a1a;
          }

          .issue-info-header-type-documentation {
            color: $data-blue-dark;
            box-shadow: 0 0 0 1px $data-blue-dark;
            background-color: rgba($data-blue-dark, var(--opacity-3));
          }

          .issue-info-header-type-accessibility {
            color: $neutral-10;
            box-shadow: 0 0 0 1px $neutral-10;
            background-color: rgba($neutral-10, var(--opacity-3));
          }
        }

        .effortLevelStyle {
          background-color: $color-background-default;
          color: $color-text-heading;
        }

        .seriousStyle,
        .criticalStyle,
        .minorStyle,
        .moderateStyle {
          margin-left: $spacing-4;
        }
        .seriousStyle {
          color: $data-tangerine-light !important;
          box-shadow: 0 0 0 1px #ffa929 !important;
          background-color: rgba(255, 169, 41, 0.12) !important;
        }
        .criticalStyle {
          background-color: rgba(197, 42, 26, 0.12) !important;
          color: $color-text-error;
          box-shadow: 0 0 0 0.0625rem #c52a1a;
        }
        .minorStyle {
          color: $neutral-10 !important;
          box-shadow: 0 0 0 1px #979797 !important;
          background-color: rgba(151, 151, 151, 0.12) !important;
        }
        .moderateStyle {
          color: $color-text-primary !important;
          box-shadow: 0 0 0 0.0625rem #415385 !important;
          background-color: rgba(65, 83, 133, 0.12) !important;
        }

        // @media screen and (max-width: 1048px) {
        //   flex-direction: column;
        //   .seriousStyle,
        //   .criticalStyle,
        //   .minorStyle,
        //   .moderateStyle {
        //     margin-left: 0;
        //   }
        //   .tagSingle:not(:first-child) {
        //     margin-top: $spacing-2;
        //   }
        // }
      }
    }
  }
  }
  .isdThreeDotsArea {
    width: rem(40px);
    color: $color-text-body;
    display: flex;
    // justify-content: center;
    align-items: center;
    border-radius: $border-radius-2;
    // transform: translateX(-5px);
    .ap-buttons-wrapper .custom+div.ap-button-dropdown.align-right {
      top: 50%;
      transform: translateY(rem(16px));
    }
    &.manual-last-one {
      .ap-buttons-wrapper .custom+div.ap-button-dropdown.align-right {
        bottom: 50%;
        transform: translateY(rem(-32px));
        top: unset;
      }
    }
    .ap-buttons-wrapper {
      width: rem(40px) !important;
      height: rem(40px) !important;
      display: flex;
      justify-content: center;
      align-items: center;

      .ap-button-dropdown {
        // box-shadow: $box-shadow-3 !important;
        //color-background-tertiary-rgb
      }
      .custom-node{
        @include threeDotsButtonCustomNode;
      }
    }
    .ap-buttons-wrapper:hover {
      background-color: $color-background-hover;
    }
    .ap-button-dropdown.open{
      .ap-option-item:has(.icon-delete-outline) {
        color: $color-background-danger;
      }
    }
  }


[data-mode='dark'] {
  .tagSingle {
    .issue-info-header-type-general {
      color: $primary-blue-02 !important;
      box-shadow: 0 0 0 rem(1px) $primary-blue-02 !important;
      background-color: rgba($primary-blue-02, var(--opacity-3)) !important;
    }

    .issue-info-header-type-bug {
      color: $data-blue !important;
      box-shadow: 0 0 0 1px $data-blue !important;
    }

    .issue-info-header-type-access {
      color: $data-blue !important;
      box-shadow: 0 0 0 1px $data-blue !important;
      background-color: rgba($data-blue, var(--opacity-3)) !important;
    }

    .issue-info-header-type-feature {
      color: $data-green !important;
      box-shadow: 0 0 0 1px $data-green !important;
      background-color: rgba($data-green, var(--opacity-3)) !important;
    }

    .issue-info-header-type-component {
      color: $data-purple !important;
      box-shadow: 0 0 0 1px $data-purple !important;
      background-color: rgba($data-purple, var(--opacity-3)) !important;
    }

    .issue-info-header-type-contribution {
      color: $neutral-05 !important;
      box-shadow: 0 0 0 1px $neutral-05 !important;
      background-color: rgba($neutral-05, var(--opacity-3)) !important;
    }

    .issue-info-header-type-installation {
      color: $neutral-10 !important;
      box-shadow: 0 0 0 1px $neutral-10 !important;
      background-color: rgba($neutral-10, var(--opacity-3)) !important;
    }

    .issue-info-header-type-design {
      color: #ffffff !important;
      box-shadow: 0 0 0 1px $data-tangerine-light !important;
      background-color: rgba($data-tangerine-light, var(--opacity-3)) !important;
    }

    .issue-info-header-type-danger {
      color: #dc7f76 !important;
    }

    .issue-info-header-type-documentation {
      color: $data-blue !important;
      box-shadow: 0 0 0 1px $data-blue !important;
      background-color: rgba($data-blue, var(--opacity-3)) !important;
    }

    .issue-info-header-type-accessibility {
      color: $neutral-10 !important;
      box-shadow: 0 0 0 1px $neutral-10 !important;
      background-color: rgba($neutral-10, var(--opacity-3)) !important;
    }

    .ap-badge.ap-badge-info.issueCard {
      background-color: $color-background-selected;
      color: $color-text-body;
    }
  }
  .isdThreeDotsArea {
    .ap-buttons-wrapper {
      .ap-button-dropdown {
        box-shadow: $level-3 !important;
      }
    }
  }
}

.manual-page-wrapper{
  display: none;
}

.detail-dashboardPart {
  .sectionWrapper {
    .isdTextArea {
      width: 65%;
    }
  }

  .tagPart {
    width: 35%;
  }
}
