@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';

.ap-page-help-center-welcome {
    &.has-anchor {
        display: grid;
        grid-template-columns: rem(845px) auto;
        @media screen and (max-width: 1427px) {
            grid-template-columns: 100%;
        }
    }
    .ap-help-center-welcome-container {
        .ap-help-center-welcome-card-wrapper {
            margin-top: $spacing-6;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: $spacing-5;

            .ap-help-center-welcome-card {
                min-height: rem(152px);
                cursor: pointer;
                border-radius: $border-radius-3;
                background-color: $color-background-container;
                box-shadow: $box-shadow-1;
                padding: $spacing-6;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                &.coming-soon {
                    cursor: not-allowed;
                }
                &:not(.coming-soon):hover {
                    box-shadow: $box-shadow-2;
                }

                &-header {
                    display: flex;
                    justify-content: space-between;

                    &-title {
                        color: $color-text-heading;
                        font-size: $typography-text-size-4;
                        font-weight: $font-weight-2;
                        letter-spacing: $letter-spacing-1;
                        line-height: $typography-line-height-3;
                        align-self: flex-start;
                        display: flex;
                        flex-direction: column;

                        &-text {
                            max-width: 174px;
                        }
                    }

                    &-icon {
                        height: $height-7;
                        width: $width-18;
                        color: $color-text-heading;
                        font-size: $typography-text-size-7;
                        line-height: $typography-line-height-7;
                        text-align: center;
                    }

                    &-mask-icon {
                        mask-image: url('../../assets/icons/Figma-icon.svg');
                        height: $height-7;
                        width: $width-18;
                        background-color: $color-text-heading;
                    }
                }

                &-footer {
                    max-width: rem(206px);
                    &-desc {
                        color: $color-text-light;
                        font-size: $typography-text-size-3;
                        letter-spacing: $letter-spacing-1;
                        line-height: $typography-line-height-3;
                    }
                }

                @media screen and (max-width: 801px) {
                    min-height: rem(168px);
                }
            }
        }

        .ap-help-center-welcome-question {
            margin-top: $spacing-8;

            &-title {
                color: $color-text-heading;
                font-size: $typography-text-size-4;
                font-weight: $font-weight-2;
                letter-spacing: $letter-spacing-1;
                line-height: $typography-line-height-3;
            }

            &-desc {
                color: $color-text-body;
                font-size: $typography-text-size-3;
                letter-spacing: $letter-spacing-1;
                line-height: $typography-line-height-3;
                margin-top: $spacing-4;
            }
        }
    }
}

.ap-component-wrapper {
    margin-top: $spacing-6;
    margin-bottom: $spacing-8;

    .ap-component-name {
        color: $color-text-heading;
        font-size: $typography-text-size-5;
        line-height: $typography-line-height-5;
        font-weight: $font-weight-2;
        letter-spacing: $letter-spacing-1;
        display: flex;
        align-items: center;
        grid-column: 1 / 3;
        margin-bottom: $spacing-4;
        margin-top: 0;
        &.help-center {
            margin-bottom: rem(16px);
        }
        .Appkit4-icon {
            font-size: $typography-text-size-4;
            margin-left: $spacing-3;
        }
    }

    .ap-page-desc {
        font-size: $typography-text-size-3;
        line-height: $typography-line-height-3;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: $letter-spacing-1;
        color: $color-text-body;
        word-wrap: break-word;
    }
}
.ap-container.home.resource {
    align-items: unset;
    margin-top: rem(64px);
    height: calc(100vh - rem(64px));
}

// responsive
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .ap-page-help-center-welcome .ap-help-center-welcome-container .ap-help-center-welcome-card-wrapper {
        grid-template-columns: repeat(2, 1fr);
        .ap-help-center-welcome-card{
            min-width: rem(212px);
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .ap-page-help-center-welcome .ap-help-center-welcome-container .ap-help-center-welcome-card-wrapper {
        grid-template-columns: repeat(3, 1fr);
    }

    .dashboard-wrapper-container .itemFooterContent {
        padding-left: 0;
        padding-right: 0;
        max-width: calc(100% - rem(40px));
    }
}

@media screen and (min-width: 1440px) {
    .ap-page-help-center-welcome .ap-help-center-welcome-container .ap-help-center-welcome-card-wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (min-width: 1441px) {
    .not-first-use-tool-footer.help-center {
        .footer-container {
            margin-left: auto;
            margin-right: auto;
            width: rem(1080px);
            // @media screen and (min-width: 1480px) {
            //     padding-left: 0;
            // }
        }
    }
}

.dashboard-wrapper-container .outletcontainer {
    &.resource{
        margin-left: rem(280px);
    }
}

@media screen and (min-width: 1441px) {
        .ap-container.home.resource:has(.ap-page-help-center-welcome){
        .ap-page.ap-page-help-center-welcome.has-anchor {
            margin-left: auto;
            margin-right: auto;
            width: rem(1080px);
        }   
    }
}

@media screen and (max-width: 1024px) {
    .ap-help-center-hub-welcome .ap-help-center-welcome-container .ap-help-center-welcome-card-wrapper .ap-help-center-welcome-card-header-title-text {
        max-width: rem(108px);
        word-break: break-word;
    }
}

.ap-help-center-welcome-card-header-title {
    .ap-badge.ap-badge-info {
        width: rem(96px);
        padding: $spacing-2;
        .ap-badge-text {
            font-size: rem(14px);
            line-height: rem(16px);
            text-wrap: nowrap;
            font-weight: $font-weight-1;
            letter-spacing: rem(-0.4px);
        }
    }
}

// @media screen and (min-width: 768px) and (max-width: 1439px) {
//     .ap-container.home.resource {
//         align-items: unset;
//     }
// }
// @media screen and (min-width: 1441px) {
//     .ap-container.home.resource:has(.ap-page-help-center-welcome) {
//         width: rem(1110px);
//     }
// }
.not-first-use-tool-footer {
    &.help-center {
        width: 100%;
    }
}
.ap-container.home.resource {
    align-items: unset;
}
@media screen and (min-width: 900px) {
    .ap-container.home.resource:has(.ap-page-help-center-welcome) {
        max-width: unset!important;
    }
}
[data-mode='dark'] {
    .ap-help-center-welcome-card-header-title {
        .ap-badge.ap-badge-info {
            background-color: $color-background-selected;
            color: $color-text-body;
        }
    }
}
