@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';
.ap-feedback-comment {
  max-width: 100%;
  border-radius: $border-radius-3;
  position: relative;

  .tab-container .ap-tabset-container .ap-tabset-toggle-wrapper.filled .ap-tabset-toggle.has-label.active {
    background-color: $color-background-container;
    color: $color-text-body;
  }

  .tab-container
    .ap-tabset-container
    .ap-tabset-toggle-wrapper.filled
    .ap-tabset-toggle.has-label.active
    .ap-tabset-toggle-active {
    color: $color-text-body;
  }

  &-texteditor-area {
    width: 100%;
    height: 100%;

    .tab-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: $spacing-4;
    }

    .comment-title {
      color: $color-text-light;
      font-size: $typography-text-size-3;
      letter-spacing: $letter-spacing-1;
      line-height: $typography-line-height-3;
      position: absolute;
      top: rem(80px);
      left: $spacing-3;
      transition: all $transition-0;
      z-index: 2;
      cursor: default;

      display: flex;
      align-items: center;
      height: $spacing-7;
      background-color: $color-background-container;
      width: calc(100% - $spacing-3 - $spacing-3);

      &.comment-title-small {
        font-size: $typography-text-size-1;
        line-height: rem(12px);
        top: rem(73px);
      }
    }

    .write-container {
      width: 100%;
      height: 100%;

      .ap-text-editor-container.ck-editor {
        box-shadow: none;
        .ck.ck-editor__editable_inline {
          height: rem(200px);
          box-shadow: inset $shadow-border;
          border-radius: $border-radius-2;
          padding-left: $spacing-3;
          padding-top: $spacing-7;
          transition: box-shadow $transition-0;

          &.ck-content .mention {
            background: transparent;
          }

          &:focus-within {
            box-shadow:
              inset 0 0 0 rem(1px) $color-background-primary,
              0 0 0 rem(4px) rgba($color-background-primary-rgb, $opacity-3);
          }

          .language-plaintext {
            white-space: pre-wrap;
          }
        }
        pre {
          overflow: auto;
        }
        .ck-toolbar {
          .ck-toolbar__items {
            display: flex;
            justify-content: flex-end;
          }
        }
      }

      img {
        width: 80%;
      }
    }
    .notes {
      margin: $spacing-3 0 $spacing-6 0;
      color: $color-text-body;
      font-size: $typography-text-size-1;
      letter-spacing: $letter-spacing-1;
      line-height: $typography-line-height-1;

      span {
        letter-spacing: rem(-0.2px);
        line-height: rem(12px);
        font-weight: $font-weight-1;
      }
    }
  }

  &-upload-area {
    .fileupload-demo-wrapper {
      position: relative;
      max-width: 100%;
      margin-bottom: rem(10px);

      .hide {
        display: none;
      }

      .comment-upload {
        .ap-fileupload-title.title-inline {
          display: none;
        }
        .ap-fileupload-instruction.instruction-inline {
          display: none;
        }
        .ap-fileupload-content {
          .ap-fileupload-drop-span {
            position: absolute;
            left: 0;
            right: 0;
            top: $spacing-3;
          }
        }
      }

      .desc-upload-new {
        display: flex;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        top: rem(40px);
        color: $color-text-light;
        font-size: $typography-text-size-1;
        letter-spacing: rem(-0.2px);
        line-height: $typography-line-height-1;

        &:hover {
          cursor: pointer;
        }
      }
      .ap-fileupload.newIssue.comment-upload {
        .ap-fileupload-title.title-inline {
          display: none;
        }
        .ap-fileupload-instruction.instruction-inline {
          display: none;
        }
        .ap-fileupload-content {
          .ap-fileupload-drop-span {
            position: absolute;
            left: 0;
            right: 0;
            top: $spacing-3;
          }
        }
      }
    }

    .opertaion-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: rem(10px) 0 0 0;
      .comment-submit {
        margin-left: $spacing-3;
      }
    }
  }
}

.ck.ck-reset.ck-list.ck-mentions {
  width: 20.6875rem;
  padding: rem(8px);
  background-color: $color-background-container;
  .ck.ck-list__item .ck-button.ck-on {
    background-color: $color-background-hover;
  }
  .ck.ck-list__item:not(:last-child) {
    border-radius: $border-radius-2;
    margin-bottom: rem(4px);
  }
  .ck-list__item .ck-button:hover:not(.ck-disabled) {
    border-radius: $border-radius-2;
    background-color: $color-background-hover;
  }
  .list-content {
    display: flex;
    align-items: center;

    // &:hover{
    //   background-color: $color-background-hover;
    //   border-radius: $border-radius-2;
    // }

    .avatar-name-box {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-background-primary;
      color: $color-text-secondary;
      font-weight: $font-weight-2;
      padding: $spacing-3;
      line-height: rem(24px);
      width: rem(40px);
      height: rem(40px);
      margin: rem(12px);
      z-index: 0;
      // background-color: $primary-blue-04;
      .name-text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 1rem;
        color: $neutral-01;
      }
    }

    .userinfo-box {
      display: flex;
      flex-direction: column;

      .userinfo-full-name,
      .userinfo-email {
        height: $height-3;
        font-size: 1rem;
        letter-spacing: $letter-spacing-1;
        line-height: $height-3;
      }

      .userinfo-full-name {
        color: $color-text-body;
      }

      .userinfo-email {
        color: $color-text-light;
      }
    }
  }
}
