$clip-4: rect(0px, 16px, 16px, 8px);
$clip-3: rect(0px, 8px, 16px, 0px);
$clip-2: rect(0px, 24px, 24px, 12px);
$clip-1: rect(0px, 12px, 24px, 0px);
$degree-2: 135deg;
$degree-1: -180deg;
$blur-2: var(--blur-2);
$blur-1: var(--blur-1);
$transition-2: 2s;
$transition-1: 0.5s;
$transition-0: 0.3s;
$z-index-99: 99;
$z-index-1: 1;
$z-index-0: -1;
$letter-spacing-1: rem(-0.4px);
$font-weight-6: 600;
$font-weight-3: var(--font-weight-3);
$font-weight-2: var(--font-weight-2);
$font-weight-1: var(--font-weight-1);
$height-113: rem(56px);
$height-112: rem(112px);
$height-88: rem(88px);
$height-28: rem(28px);
$height-21: rem(21px);
$height-20: rem(12px);
$height-18: rem(18px);
$height-17: rem(4px);
$height-16: rem(72px);
$height-15: rem(60px);
$height-14: rem(6px);
$height-13: rem(22.88px);
$height-12: rem(220px);
$height-11: rem(64px);
$height-10: rem(32px);
$height-9: rem(62px);
$height-8: rem(34px);
$height-7: rem(48px);
$height-6: rem(42px);
$height-5: rem(40px);
$height-4: rem(36px);
$height-3: rem(24px);
$height-2: rem(20px);
$height-1: rem(16px);
$height-0: rem(8px);
$width-datepicker-2: rem(320px);
$width-datepicker-1: rem(304px);
$width-600: rem(600px);
$width-542: rem(542px);
$width-516: rem(516px);
$width-280: rem(280px);
$width-256: rem(256px);
$width-28: rem(28px);
$width-21: rem(32px);
$width-20: rem(12px);
$width-19: rem(18px);
$width-18: rem(48px);
$width-17: rem(6px);
$width-16: rem(13px);
$width-15: rem(320px);
$width-14: rem(104px);
$width-13: rem(102px);
$width-12: rem(100px);
$width-11: rem(98px);
$width-10: rem(77px);
$width-9: rem(75px);
$width-8: rem(73px);
$width-7: rem(44px);
$width-6: rem(40px);
$width-5: rem(42px);
$width-4: rem(36px);
$width-3: rem(24px);
$width-2: rem(20px);
$width-1: rem(16px);
$width-0: rem(8px);
$border-3: rem(6px);
$border-2: rem(4px);
$border-1: rem(1px);
$opacity-7: var(--opacity-7);
$opacity-6: var(--opacity-6);
$opacity-5: var(--opacity-5);
$opacity-4: var(--opacity-4);
$opacity-3: var(--opacity-3);
$opacity-2: var(--opacity-2);
$opacity-1: var(--opacity-1);
$box-shadow-3: var(--box-shadow-3);
$box-shadow-2: var(--box-shadow-2);
$box-shadow-1: var(--box-shadow-1);
$level-3: var(--level-3);
$level-2: var(--level-2);
$level-1: var(--level-1);
$shadow-dropdown-2: 0 0 rem(1px) 0 rgba(0, 0, 0, 0.04);
$shadow-dropdown-1: 0 rem(8px) rem(16px) rem(-4px) rgba(0, 0, 0, 0.24);
$border-type-1: solid;
$border-radius-7: 50%;
$border-radius-6: rem(20px);
$border-radius-5: rem(16px);
$border-radius-4: rem(12px);
$border-radius-3: var(--border-radius-3);
$border-radius-2: var(--border-radius-2);
$border-radius-1: var(--border-radius-1);
$typography-letter-spacing-1: rem(-0.4px);
$typography-line-height-size-data: rem(48px);
$typography-line-height-size-large-heading: rem(42px);
$typography-line-height-size-heading: rem(32px);
$typography-line-height-size-subheading: rem(24px);
$typography-line-height-size-body: rem(24px);
$typography-line-height-size-body-s: rem(20px);
$typography-line-height-size-body-xs: rem(14px);
$typography-line-height-40: rem(40px);
$typography-line-height-16: rem(16px);
$typography-line-height-7: rem(48px);
$typography-line-height-6: rem(36px);
$typography-line-height-5: rem(32px);
$typography-line-height-4: rem(24px);
$typography-line-height-3: rem(24px);
$typography-line-height-2: rem(20px);
$typography-line-height-1: rem(14px);
$typography-text-size-data: rem(48px);
$typography-text-size-large-heading: rem(32px);
$typography-text-size-heading: rem(24px);
$typography-text-size-subheading: rem(20px);
$typography-text-size-body: rem(16px);
$typography-text-size-body-s: rem(14px);
$typography-text-size-body-xs: rem(12px);
$typography-text-size-7: rem(48px);
$typography-text-size-6: rem(32px);
$typography-text-size-5: rem(24px);
$typography-text-size-4: rem(20px);
$typography-text-size-3: rem(16px);
$typography-text-size-2: rem(14px);
$typography-text-size-1: rem(12px);
$typography-large-heading: var(--typography-large-heading);
$typography-heading: var(--typography-heading);
$typography-subheading: var(--typography-subheading);
$typography-data: var(--typography-data);
$typography-heading-l: var(--typography-heading-l);
$typography-heading-m: var(--typography-heading-m);
$typography-heading-s: var(--typography-heading-s);
$typography-body: var(--typography-body);
$typography-body-s: var(--typography-body-s);
$typography-body-xs: var(--typography-body-xs);
$typography-6: var(--typography-6);
$typography-5: var(--typography-5);
$typography-4: var(--typography-4);
$typography-3: var(--typography-3);
$typography-2: var(--typography-2);
$typography-1: var(--typography-1);
$spacing-36: rem(36px);
$spacing-32: rem(32px);
$spacing-18: rem(18px);
$spacing-10: rem(10px);
$spacing-9: rem(9px);
$spacing-8: var(--spacing-8);
$spacing-7: var(--spacing-7);
$spacing-6: var(--spacing-6);
$spacing-5: var(--spacing-5);
$spacing-4: var(--spacing-4);
$spacing-3: var(--spacing-3);
$spacing-2: var(--spacing-2);
$spacing-1: var(--spacing-1);
$states-success-09: #1f3b29;
$states-success-08: #20522a;
$states-success-07: #206a2c;
$states-success-06: #21812d;
$states-success-05: #22992e;
$states-success-04: #4ead58;
$states-success-03: #7ac282;
$states-success-02: #a7d6ab;
$states-success-01: #d3ebd5;
$states-warning-09: #4b4226;
$states-warning-08: #786124;
$states-warning-07: #a58123;
$states-warning-06: #d2a021;
$states-warning-05: #ffbf1f;
$states-warning-04: #ffcc4c;
$states-warning-03: #ffd979;
$states-warning-02: #ffe5a5;
$states-warning-01: #fff2d2;
$states-error-09: #3f2425;
$states-error-08: #612622;
$states-error-07: #822720;
$states-error-06: #a4291d;
$states-error-05: #c52a1a;
$states-error-04: #d15548;
$states-error-03: #dc7f76;
$states-error-02: #e8aaa3;
$states-error-01: #f3d4d1;
$neutral-09: #a3a3a3;
$neutral-08: #afafaf;
$neutral-07: #bababa;
$neutral-06: #c5c5c5;
$neutral-05: #d1d1d1;
$neutral-04: #dddddd;
$neutral-03: #e8e8e8;
$neutral-02: #f3f3f3;
$neutral-01: #ffffff;
$neutral-23: #000000;
$neutral-22: #111111;
$neutral-21: #191919;
$neutral-20: #252525;
$neutral-19: #303030;
$neutral-18: #3b3b3b;
$neutral-17: #474747;
$neutral-16: #535353;
$neutral-15: #5e5e5e;
$neutral-14: #696969;
$neutral-13: #757575;
$neutral-12: #818181;
$neutral-11: #8c8c8c;
$neutral-10: #979797;
$data-green-darker: #175c2c;
$data-green-dark: #2c8646;
$data-green: #4eb523;
$data-green-light: #86db4f;
$data-green-lighter: #c4fc9f;
$data-blue-darker: #003dab;
$data-blue-dark: #0060d7;
$data-blue: #0089eb;
$data-blue-light: #4dacf1;
$data-blue-lighter: #b3dcf9;
$data-purple-darker: #4b20ab;
$data-purple-dark: #6b2cda;
$data-purple: #8e34f4;
$data-purple-light: #b056f6;
$data-purple-lighter: #dcb4fc;
$data-gray-darker: #141414;
$data-gray-dark: #2d2d2d;
$data-gray: #7d7d7d;
$data-gray-light: #dedede;
$data-gray-lighter: #f2f2f2;
$data-rose-darker: #6e2a35;
$data-rose-dark: #a43e50;
$data-rose: #d93954;
$data-rose-light: #e27588;
$data-rose-lighter: #f1bac3;
$data-red-darker: #741910;
$data-red-dark: #aa2417;
$data-red: #e0301e;
$data-red-light: #e86153;
$data-red-lighter: #f7c8c4;
$data-yellow-darker: #855f00;
$data-yellow-dark: #c28a00;
$data-yellow: #ffb600;
$data-yellow-light: #ffc83d;
$data-yellow-lighter: #ffecbd;
$data-tangerine-darker: #714300;
$data-tangerine-dark: #ae6800;
$data-tangerine: #eb8c00;
$data-tangerine-light: #ffa929;
$data-tangerine-lighter: #ffdca9;
$data-orange-darker: #571f01;
$data-orange-dark: #933401;
$data-orange: #d04a02;
$data-orange-light: #fd6412;
$data-orange-lighter: #feb791;
$secondary-red-20: #fad5d6;
$secondary-red-40: #f5aaad;
$secondary-red-60: #f18085;
$secondary-red-80: #ec555c;
$secondary-red: #e72b33;
$secondary-purple-20: #e0e5ff;
$secondary-purple-40: #c1cbff;
$secondary-purple-60: #a3b1ff;
$secondary-purple-80: #8497ff;
$secondary-purple: #657dff;
$secondary-rose-20: #f8dde1;
$secondary-rose-40: #f1bac3;
$secondary-rose-60: #e998a6;
$secondary-rose-80: #e27588;
$secondary-rose: #d93954;
$secondary-teal-20: #d8f7ef;
$secondary-teal-40: #c4f2e7;
$secondary-teal-60: #9ee9d7;
$secondary-teal-80: #77e1c7;
$secondary-teal: #3dd5b0;
$secondary-lightblue-20: #ccf4fd;
$secondary-lightblue-40: #b2edfc;
$secondary-lightblue-60: #7fe2fb;
$secondary-lightblue-80: #4cd7f9;
$secondary-lightblue: #00c6f7;
$secondary-blue-20: #dae4f4;
$secondary-blue-40: #c7d6ee;
$secondary-blue-60: #a2bbe4;
$secondary-blue-80: #7ca0d9;
$secondary-blue: #4577c9;
$primary-blue-09: #060b16;
$primary-blue-08: #0d152d;
$primary-blue-07: #132043;
$primary-blue-06: #1a2a5a;
$primary-blue-05: #203570;
$primary-blue-04: #415385;
$primary-blue-03: #62719a;
$primary-blue-02: #9aa4be;
$primary-blue-01: #d2d7e2;
$primary-orange-09: #472b24;
$primary-orange-08: #773829;
$primary-orange-07: #a7452c;
$primary-orange-06: #c34c2f;
$primary-orange-05: #d04a02;
$primary-orange-04: #e45c2b;
$primary-orange-03: #fb7c4d;
$primary-orange-02: #fdab8d;
$primary-orange-01: #fedacc;
$primary-teal-09: #223937;
$primary-teal-08: #245952;
$primary-teal-07: #26776d;
$primary-teal-06: #27897e;
$primary-teal-05: #299d8f;
$primary-teal-04: #49aba0;
$primary-teal-03: #69bab0;
$primary-teal-02: #9ed3cc;
$primary-teal-01: #d4ebe9;
$primary-pink-09: #462b2f;
$primary-pink-08: #6b343d;
$primary-pink-07: #903f4d;
$primary-pink-06: #b5485b;
$primary-pink-05: #d93954;
$primary-pink-04: #e27588;
$primary-pink-03: #e998a6;
$primary-pink-02: #f1bac3;
$primary-pink-01: #f8dde1;
$primary-red-09: #472420;
$primary-red-08: #772820;
$primary-red-07: #a62b1e;
$primary-red-06: #c22d1d;
$primary-red-05: #e0301e;
$primary-red-04: #e44f3f;
$primary-red-03: #e96e61;
$primary-red-02: #f1a29a;
$primary-red-01: #f9d6d2;
$color-text-link-secondary-inverse-rgb: var(--color-text-link-secondary-inverse-rgb);
$color-text-link-primary-inverse-rgb: var(--color-text-link-primary-inverse-rgb);
$color-text-link-secondary-rgb: var(--color-text-link-secondary-rgb);
$color-text-link-primary-rgb: var(--color-text-link-primary-rgb);
$color-text-warning-rgb: var(--color-text-warning-rgb);
$color-text-success-rgb: var(--color-text-success-rgb);
$color-text-error-rgb: var(--color-text-error-rgb);
$color-text-disabled-rgb: var(--color-text-disabled-rgb);
$color-text-body-rgb: var(--color-text-body-rgb);
$color-text-heading-rgb: var(--color-text-heading-rgb);
$color-text-light-rgb: var(--color-text-light-rgb);
$color-text-tertiary-rgb: var(--color-text-tertiary-rgb);
$color-text-secondary-rgb: var(--color-text-secondary-rgb);
$color-text-primary-rgb: var(--color-text-primary-rgb);
$color-text-link-secondary-inverse: var(--color-text-link-secondary-inverse);
$color-text-link-primary-inverse: var(--color-text-link-primary-inverse);
$color-text-link-secondary: var(--color-text-link-secondary);
$color-text-link-primary: var(--color-text-link-primary);
$color-text-warning: var(--color-text-warning);
$color-text-success: var(--color-text-success);
$color-text-error: var(--color-text-error);
$color-text-disabled: var(--color-text-disabled);
$color-text-body: var(--color-text-body);
$color-text-heading: var(--color-text-heading);
$color-text-light: var(--color-text-light);
$color-text-tertiary: var(--color-text-tertiary);
$color-text-secondary: var(--color-text-secondary);
$color-text-primary: var(--color-text-primary);
$color-background-icon-alt-rgb: var(--color-background-icon-alt-rgb);
$color-background-icon-secondary-rgb: var(--color-background-icon-secondary-rgb);
$color-background-icon-primary-rgb: var(--color-background-icon-primary-rgb);
$color-background-warning-inverse-rgb: var(--color-background-warning-inverse-rgb);
$color-background-success-inverse-rgb: var(--color-background-success-inverse-rgb);
$color-background-danger-inverse-rgb: var(--color-background-danger-inverse-rgb);
$color-background-warning-rgb: var(--color-background-warning-rgb);
$color-background-success-rgb: var(--color-background-success-rgb);
$color-background-danger-rgb: var(--color-background-danger-rgb);
$color-background-border-rgb: var(--color-background-border-rgb);
$color-background-hover-selected-rgb: var(--color-background-hover-selected-rgb);
$color-background-selected-rgb: var(--color-background-selected-rgb);
$color-background-hover-rgb: var(--color-background-hover-rgb);
$color-background-tertiary-rgb: var(--color-background-tertiary-rgb);
$color-background-secondary-rgb: var(--color-background-secondary-rgb);
$color-background-primary-rgb: var(--color-background-primary-rgb);
$color-background-container-inverse-rgb: var(--color-background-container-inverse-rgb);
$color-background-container-alt-rgb: var(--color-background-container-alt-rgb);
$color-background-container-rgb: var(--color-background-container-rgb);
$color-background-alt-inverse-rgb: var(--color-background-alt-inverse-rgb);
$color-background-alt-rgb: var(--color-background-alt-rgb);
$color-background-default-inverse-rgb: var(--color-background-default-inverse-rgb);
$color-background-default-rgb: var(--color-background-default-rgb);
$color-background-icon-alt: var(--color-background-icon-alt);
$color-background-icon-secondary: var(--color-background-icon-secondary);
$color-background-icon-primary: var(--color-background-icon-primary);
$color-background-warning-inverse: var(--color-background-warning-inverse);
$color-background-success-inverse: var(--color-background-success-inverse);
$color-background-danger-inverse: var(--color-background-danger-inverse);
$color-background-warning: var(--color-background-warning);
$color-background-success: var(--color-background-success);
$color-background-danger: var(--color-background-danger);
$color-background-border: var(--color-background-border);
$color-background-triple-hover: var(--color-background-triple-hover);
$color-background-hover-selected: var(--color-background-hover-selected);
$color-background-selected: var(--color-background-selected);
$color-background-hover: var(--color-background-hover);
$color-background-tertiary: var(--color-background-tertiary);
$color-background-secondary: var(--color-background-secondary);
$color-background-primary-code: var(--color-background-primary-code);
$color-background-primary-light: var(--color-background-primary-light);
$color-background-primary: var(--color-background-primary);
$color-background-container-inverse: var(--color-background-container-inverse);
$color-background-container-alt: var(--color-background-container-alt);
$color-background-container: var(--color-background-container);
$color-background-alt-inverse: var(--color-background-alt-inverse);
$color-background-alt: var(--color-background-alt);
$color-background-default-inverse: var(--color-background-default-inverse);
$color-background-default: var(--color-background-default);
$shadow-border-5: 0 0 0 rem(1px) $color-background-tertiary;
$shadow-border-4: 0 0 0 rem(4px) rgba($color-background-primary-rgb, 0.12);
$shadow-border-3: 0 0 0 rem(1px) rgba($color-background-primary-rgb, 1);
$shadow-border-2: 0 0 0 rem(1px) rgba($color-background-tertiary-rgb, 0.08);
$shadow-border: 0 0 0 rem(1px) $color-background-border;
$border-color-1: rgba($color-background-tertiary-rgb, 0.24);
