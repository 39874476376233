@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';

@mixin fontStyle($size, $weight, $lineHeight, $letterSpacing, $color) {
    color: $color;
    font-size: $size;
    font-weight: $weight;
    line-height: $lineHeight;
    letter-spacing: $letterSpacing;
}

.pattern-content {
    display: flex;

    &.help-center-faq-content {
        * {
            scroll-margin-top: rem(64px);
        }
        a {
            &:hover {
                text-decoration: none!important;
            }
        }
    }

    .ap-navigation-demo-wrapper {
        position: fixed;
        left: 0;
        top: 0;
    }

    .ap-faqs {
        display: flex;
        margin: rem(16px) 0 0 0;
        padding: 0;
        transition: width .3s;
        min-height: calc(100vh - 3.25rem);

        .ap-faqs-header {
            margin-top: 0;
        }

        &.has-anchor {
            display: flex;
            // justify-content: center;
            min-width: 100%;
            transition: none;

            &.faq-page-collapse {
                margin-left: rem(72px);
                min-width: 100%;
            }

            .ap-page-content {
                width: calc(100vw - rem(638px));
                .ap-breadcrumb .ap-breadcrumb-item:last-child {
                    pointer-events: none;
                }
            }
        }

        &-header {
            margin-top: $spacing-8;

            &-title {
                display: flex;
                align-items: center;
                height: rem(42px);
                margin: rem(32px) 0 rem(16px);
                @include fontStyle(rem(24px), $font-weight-2, $typography-line-height-5, $letter-spacing-1, $color-text-heading);
            }

            &-desc {
                margin-bottom: $spacing-6;
                @include fontStyle($typography-text-size-3, normal, $typography-line-height-3, $letter-spacing-1, $color-text-body);
            }
        }
    }
}

.ap-pattern-faq-list {

    &.faq-list {
        width: 100%;
    }

    .ap-pattern-faq-section {
        &:not(:first-of-type) {
            margin-top: $spacing-7;
        }
    }

    .ap-pattern-faq-subtitle {
        font: $font-weight-2 $typography-4 'PwC Helvetica Neue';
        color: $color-text-heading;
        padding-bottom: $spacing-4;
        font-size: rem(20px);
    }

    .ap-pattern-faq-content {
        max-width: calc(100%);
        padding: rem(20px);
        border-radius: $border-radius-3;
        box-shadow: $box-shadow-1;
        background-color: $color-background-container;

        ::ng-deep .list-style-disc {
            list-style: disc;
        }
        .ap-accordion .ap-accordion-content .ap-accordion-wrapper{
            padding-bottom: rem(12px);
        }
    }

    .ap-accordion-group-container {
        margin: 0;
    
        .ap-accordion:hover {
            background-color: $color-background-hover;
        }
    
        .ap-accordion-toggle {
            padding: $spacing-4 !important;
        }
    
        .ap-accordion-content {
            padding-left: 0;
            ul {
                list-style-type: disc;
            }
    
            .a-link {
                color: $color-text-primary;
                font-weight: $font-weight-2;
                cursor: pointer;
            }
    
            .ap-accordion-text {
                margin-left: $spacing-4;
                a {
                    color: $color-text-primary;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
    
            p {
                margin-top: 0;
                // margin-bottom: $spacing-5;
                padding-right: rem(8px);
            }
    
            ol,
            // ul {
            //     list-style: none;
            //     padding: 0;
            //     margin: 0;
            // }
    
            .having-issue {
                margin-bottom: $spacing-2;
            }
    
            .registry-issue {
                margin-bottom: $spacing-5;
    
                &-tip {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.header-demo-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.pattern-content {
    .ap-navigation-wrapper {
        .ap-navigation-content {
            position: relative;
            top: rem(64px);
            padding-top: rem(44px);
        }
    }
}

.dashboard-wrapper-container .itemFooterContent {
    padding-left: rem(25px);
    padding-right: rem(25px);
}

.ap-container.home.resource:has(.pattern-content.faq-content) {
    height: unset !important;
}

@media screen and (max-width: 1024px) {
    .pattern-content.faq-content {
        width: 100% !important;
    }

    .ap-pattern-faq-list {
        &.faq-list {
            width: 100%;
        }
    }

    // .pattern-content .ap-faqs.has-anchor .ap-page-content {
    //     width: 100%;
    // }
}

@media screen and (max-width: 1427px) {
    .ap-page.ap-faqs.has-anchor {
        .ap-pattern-page-anchor.anchor-list {
            display: none;
        }
    }
    .ap-container.home.resource{
        .ap-page-content {
            width: calc(100vw - rem(328px));
        }
    }
}

@media screen and (min-width: 1441px) {
    .ap-container.home.resource:has(.help-center-faq-content) {
        width: rem(1160px);
        .ap-page-content{
            width: rem(845px);
        }
    }
}

@media screen and (max-width: 768px) {
    .ap-container.home.resource:has(.help-center-faq-content) {
        padding-left: var(--ap-gutter-x, 0.5rem) !important;
    }
}
@media (min-width: 900px) {
    .ap-container.home.resource:has(.help-center-faq-content) {
        max-width: unset !important;
    }
}

// @media screen and (max-width: 768px) {
//     .header-part-container-right {
//         transform: translateX(rem(50px));
//     }
// }