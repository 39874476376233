@import 'node_modules/sass-rem/_rem.scss';
@import '../../../styles/scss/variables';
@import '../../../styles/scss/sharedStyles';

.projectDashboardContainer {
  &:hover .projectDashboardContainer-nameThreeDotsArea-name {
    color: $color-text-primary;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: unset;
  height: rem(178px);
  box-shadow: $box-shadow-1;
  border-radius: $border-radius-3;
  padding: $spacing-6;
  background-color: $color-background-container;
  cursor: pointer;

  &-nameThreeDotsArea {
    display: flex;
    justify-content: space-between;
    &-name {
      // font: $typography-heading-s;
      // letter-spacing: rem(-0.4px);
      width: 90%;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;

      h2 {
        font: $typography-heading-s;
        letter-spacing: rem(-0.4px);
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: rem(280px);
        @media screen and (min-width: 1024px) and (max-width: 1100px) {
          max-width: rem(272px);
        }
      }
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
    }
    &-dots {
      .ap-checkbox-label {
        display: none !important;
      }
    }
  }

  &-scoresArea {
    letter-spacing: rem(-0.4px);

    &-score {
      font: $typography-heading-m;
    }
    &-compliant {
      font: $typography-body !important;
      margin-left: $spacing-3;
    }
    .ap-badge span {
      font: $typography-body !important;
    }
  }

  &-desArea {
    .icon-danger,
    .icon-permittedWithCaution,
    .icon-success {
      width: rem(14px);
      height: rem(14px);
    }
    .icon-danger {
      color: $color-background-danger;
    }
    .icon-permittedWithCaution {
      color: $primary-blue-04;
    }
    .icon-success {
      color: $color-background-success;
    }

    &-desc {
      &.margin {
        margin-left: $spacing-3;
      }
      font: $typography-body-s;
      letter-spacing: rem(-0.4px);
      color: $color-text-body !important;
    }
  }

  &-timeArea {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span:first-child {
      font: $typography-body-xs;
      color: $color-text-light;
    }
  }
}

.privateModal-bodyContainer {
  //padding: $spacing-6;
  &-imgArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: rem(180px);
      height: rem(180px);
    }
    div {
      margin-top: $spacing-5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        font: $typography-heading-m;
        font-weight: $font-weight-2;
        color: $color-text-heading;
        letter-spacing: rem(-0.4px);
      }

      span {
        text-align: center;
        font: $typography-body;
        font-weight: $font-weight-1;
        letter-spacing: rem(-0.4px);
        color: $color-text-body;
      }
      a {
        text-decoration: underline;
        color: $color-text-link-primary;
      }
    }
  }
  &-assignedUserPart {
    margin-top: $spacing-6;
    max-height: rem(336px);
    overflow-y: auto;
    padding: $spacing-3;

    &-usrContainer {
      height: rem(64px);
      display: flex;
      align-items: center;

      &-nickName {
        margin-right: $spacing-4;
      }
      &-nameEmail {
        display: flex;
        flex-direction: column;
        justify-content: center;

        span:first-child {
          font: $typography-body;
          font-weight: $font-weight-2;
          letter-spacing: rem(-0.4px);
          color: $color-text-body;
        }

        span:last-child {
          font: $typography-body;
          font-weight: $font-weight-1;
          letter-spacing: rem(-0.4px);
          color: $color-text-light;
        }
      }
    }
  }
}

.privateModal-footer {
}

[data-mode='dark'] {
  .home-projectContent {
    .projectDashboardContainer {
      .projectDashboardContainer-scoresArea {
        .ap-badge.ap-badge-info {
          background-color: $color-background-selected;
          color: $color-text-body;
        }
      }
    }
  }

  .projectDashboardContainer-nameThreeDotsArea {
    &-dots {
      .ap-buttons-wrapper {
        .ap-button-dropdown {
          box-shadow: $level-3 !important;
        }
      }
    }
  }
}

.projectDashboardContainer-nameThreeDotsArea {
  &-dots {
    .ap-buttons-wrapper {
      .custom-node{
        @include threeDotsButtonCustomNode;
      }
    }
  }
}