@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';
.sideBar-container {
  padding-top: rem(64px);
  height: 100vh;

  .arrow-increase-right {
    position: absolute;
    right: rem(8px);
  }
}

.sideBarNavigation.home {
  .sideBar-container {
    padding-top: unset;
  }
}

.ap-navigation-bar {
  position: relative;
  display: flex;
  align-items: center;

  .btn-subscribe-panel {
    position: absolute;
    width: rem(256px);

    .separator-line {
      height: rem(1px);
      border-radius: rem(1px);
      margin: 0 $spacing-4;
      background-color: $color-background-border;
      margin-top: $spacing-4;
      width: 100%;
    }

    .btn-subscribe-description {
      width: 100%;
      padding: $spacing-4;
      margin-top: $spacing-4;
      .description-subscribe {
        line-height: rem(24px);
        width: rem(240px);
      }
    }
    .ap-button.ap-button-secondary.ap-button-compact {
      min-width: rem(162px);
    }
  }
}

.sideBarNavigation {
  position: fixed;
}

[data-mode='dark'] {
  .ap-navigation-wrapper:not(.solidnav) {
    background-color: unset!important;
  }
}