@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';
.modalBody {
  .modalDescription {
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.4px;
    color: #474747;
    height: 24px;
  }
  .textAreaPart {
    margin-top: 12px;
  }
}
