@import 'node_modules/sass-rem/_rem.scss';
@import '../../../styles/scss/variables';

.editOtherSettingsModal {
  &-body {
    display: flex;
    flex-direction: column;

    &-autoScansPart {
      h3 {
        font: $typography-body;
        font-weight: $font-weight-2 !important;
        letter-spacing: rem(-0.2px);
        color: $color-text-heading;
        height: rem(20px);
      }

      &-nextScanTime {
        margin-top: $spacing-4;

        &-flag {
          font: $typography-body;
          font-weight: $font-weight-1;
          letter-spacing: rem(-0.4px);
          color: $color-text-body;
          display: inline-block;
          height: rem(24px);
        }
        &-issue {
          display: flex;
          align-items: flex-start;
          background-color: $color-background-default;
          margin-top: $spacing-4;
          color: $color-text-body;
          font-feature-settings:
            'clig' off,
            'liga' off;

          font-size: rem(16px);
          font-style: normal;
          font-weight: $font-weight-1;
          line-height: rem(24px);
          letter-spacing: rem(-0.4px);
          padding: $spacing-4 $spacing-5;
          border-radius: $border-radius-3;

          .recomDescContainer {
            display: flex;
            align-items: flex-start;
            .recoDescIcon {
              margin-right: $spacing-3;
            }
          }
        }
      }

      &-selectPart {
        margin-top: $spacing-4;
      }
      &-tooltip {
        display: flex;
      }

      &-toggle {
        margin-top: $spacing-3;
        display: flex;
        font: $typography-body;
        font-weight: $font-weight-1;
        color: $color-text-body;
      }
    }
    &-autoSearchpart {
      margin-top: $spacing-6;

      h3 {
        font: $typography-body;
        font-weight: $font-weight-2 !important;
        letter-spacing: rem(-0.2px);
        color: $color-text-heading;
        height: rem(20px);
      }

      &-nextScanTime {
        margin-top: $spacing-4;
        &-flag {
          font: $typography-body;
          font-weight: $font-weight-1;
          letter-spacing: rem(-0.4px);
          color: $color-text-body;
          display: inline-block;
          height: rem(24px);
        }
        &-issue {
          display: flex;
          align-items: flex-start;
          background-color: $color-background-default;
          margin-top: $spacing-4;
          color: $color-text-body;
          font-feature-settings:
            'clig' off,
            'liga' off;

          font-size: rem(16px);
          font-style: normal;
          font-weight: $font-weight-1;
          line-height: rem(24px);
          letter-spacing: rem(-0.4px);
          padding: $spacing-4 $spacing-5;
          border-radius: $border-radius-3;

          .recomDescContainer {
            display: flex;
            align-items: flex-start;
            .recoDescIcon {
              margin-right: $spacing-3;
            }
          }
        }
      }

      &-selectPart {
        margin-top: $spacing-4;
      }
      &-tooltip {
        display: flex;
      }

      &-toggle {
        margin-top: $spacing-3;
        display: flex;
        font: $typography-body;
        font-weight: $font-weight-1;
        color: $color-text-body;
      }
    }
  }
  .sr-only{
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden; 
  }
}
