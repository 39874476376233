@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';
@import '../../../node_modules/prismjs/themes/prism.min.css';
@import '../../../node_modules/prismjs/plugins/line-numbers/prism-line-numbers.min.css';

.sample-code-single-wrapper {
    height: auto;
    border-radius: $border-radius-2;
    overflow: auto;
    margin-bottom: $spacing-3;

    .sample-code-wrapper {
      code {
        font-weight: $font-weight-1;
        font-size: rem(16px);
        line-height: rem(24px);
        letter-spacing: rem(-0.4px);
        color: $color-text-secondary;
        font-family:
          PT Mono,
          monospace;
      }
    }

    &.collapse {
      height: rem(200px);
    }

    .code-toolbar {
      padding: rem(64px) $spacing-6 $spacing-6;
    }

    code[class*='language-'] {
      text-shadow: unset !important;
    }

    code {
      counter-reset: step 1;
      counter-increment: step 1;
    }

    code .token.operator {
      background-color: unset;
      color: unset;
    }
    .token.string {
      color: unset;
    }

    pre {
      // height: 210px;
      height: auto;
      overflow-y: auto;
      // scrollbar-color: rebeccapurple green;
    }

    pre[class*='language-'] {
      background-color: unset !important;
      text-shadow: unset !important;
    }
  }

.sample-code {
  position: relative;
  * {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }

//   background-color: $color-background-default;
  .sample-code-tabs {
  }

  .simple-code-codePart {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $neutral-21;
    border-radius: $border-radius-3;

    .simple-code-codePart-cpIcon-part {
      display: flex;
      flex-direction: row-reverse;
      height: rem(64px);
      align-items: center;
      padding: $spacing-4;
      
      .sample-code-copy-icon {
        width: rem(13.17px);
        height: rem(16px);
        cursor: pointer;
        color: $color-text-secondary;
        margin-right: $spacing-4;
        position: relative;
        --copy-code-text: "Copy code";
        // &:hover {
        //   &::after {
        //     content: var(--copy-code-text);
        //     text-align: center;
        //     position: absolute;
        //     // display: block;
        //     min-width: 80px;
        //     max-width: 200px;
        //     bottom: calc(100% + 10px);
        //     backdrop-filter: $blur-2;
        //     transition: transform $transition-0, visibility $transition-0, opacity $transition-0;
        //     padding: $spacing-3;
        //     background: rgba($color-background-container-inverse-rgb, 0.72);
        //     border-radius: $border-radius-2;
        //     color: $color-background-alt;
        //     z-index: 1000;
        //   }
        // }
      }
    }

    .sample-code-single-wrapper {
      height: auto;
      border-radius: $border-radius-2;
      overflow: auto;
      margin-bottom: $spacing-3;

      .sample-code-wrapper {
        code {
          font-weight: $font-weight-1;
          font-size: rem(16px);
          line-height: rem(24px);
          letter-spacing: rem(-0.4px);
          color: $color-text-secondary;
          font-family:
            PT Mono,
            monospace;
        }
      }

      &.collapse {
        height: rem(200px);
      }

      .code-toolbar {
        padding: rem(64px) $spacing-6 $spacing-6;
      }

      code[class*='language-'] {
        text-shadow: unset !important;
      }

      code {
        counter-reset: step 1;
        counter-increment: step 1;
      }

      code .token.operator {
        background-color: unset;
        color: unset;
      }
      .token.string {
        color: unset;
      }

      pre {
        // height: 210px;
        height: auto;
        overflow-y: auto;
        // scrollbar-color: rebeccapurple green;
      }

      pre[class*='language-'] {
        background-color: unset !important;
        text-shadow: unset !important;
      }
    }
  }

  .simple-code-expandBtn {
  }
}