@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';

.verificationModal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-imgPart {
    width: rem(257px);
    height: rem(200px);

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-textPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    h2 {
      font: $typography-heading-m;
      font-weight: $font-weight-2;
      letter-spacing: rem(-0.4px);
      color: $color-text-heading;
      text-align: center;
      text-wrap: wrap;
    }

    span {
      font: $typography-body;
      font-weight: $font-weight-1;
      letter-spacing: rem(-0.4px);
      color: $color-text-body;
      text-align: left;
    }
  }

  &-tablePart {
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px $color-text-body;
      border-radius: 10px;
    }

    &-showAndUnreachPart {
      &.flexRevert {
        justify-content: flex-end;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;

      &-spanLast {
        margin-left: rem(-4px);
      }

      &-erorColor {
        color: $color-background-danger;
        font-size: $spacing-3;
      }

      &-showingPart {
        font: $typography-body-s;
        font-weight: $font-weight-1;
        letter-spacing: rem(-0.2px);
        color: $color-text-light;
        display: flex;
        align-items: center;
      }
    }

    margin-top: $spacing-6;
    width: 100%;

    &-showingPart {
      font: $typography-body-s;
      font-weight: $font-weight-1;
      letter-spacing: rem(-0.2px);
      color: $color-text-light;
    }

    &-table {
      position: relative;
      margin-top: $spacing-3;

      width: 100%;
      padding: $spacing-2 $spacing-3;
      box-shadow: inset 0 0 0 0.0625rem $color-background-border;
      border-radius: $border-radius-3;

      .Appkit4-icon::before {
        text-align: left !important;
      }

      .ap-table {
        white-space: nowrap;
        overflow-x: auto;

        box-shadow: unset;
        border-radius: unset;
        background-color: unset;

        padding-bottom: 5px;
        padding-right: 0;

        &.ap-table-condensed tbody tr td {
          padding: $spacing-4 !important;
        }

        table {
          a {
            font: $typography-body;
            font-weight: $font-weight-1;
            letter-spacing: rem(-0.4px);
            text-decoration: underline;
            color: $color-text-link-primary;
          }

          border-collapse: separate;
          border-spacing: unset !important;

          tbody tr:nth-child(odd) {
            td:nth-last-child(-n + 2) {
              background-color: $color-background-selected;
            }
          }

          tbody tr:nth-child(even) {
            td:nth-last-child(-n + 2) {
              background-color: $color-background-container;
            }
          }

          thead tr th:nth-child(1) {
            width: rem(180px);
          }

          tbody td {
            .ap-field-wrapper {
              height: rem(32px) !important;
            }

            input {
              text-align: left;
              // margin: 0 !important;
              margin: 0px 0px 0px $spacing-3 !important;
            }

            span {
              cursor: pointer;
            }
          }

          thead tr th:nth-child(3),
          tbody tr td:nth-child(3) {
            position: sticky;
            right: 0;
            border-left: 1px solid $color-background-border;
            width: rem(94px);
          }
        }
      }

      &-line {
        position: absolute;
        z-index: 99;
        width: 1px;
        background-color: $color-background-border;
        right: rem(260px);
        top: rem(12px);
        bottom: rem(10px);
      }

      &-pendingColor {
        color: $color-background-warning;
      }

      td {
        vertical-align: middle;
      }
    }

    &-pagnation {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &-pendingColor {
    color: $color-background-warning;
  }

  &-errorColor {
    color: $color-background-danger;
  }

  &-completeColor {
    color: $color-background-success;
  }

  &-scaningColor {
    color: $color-background-primary;
  }

  &-enterPart {
    height: $spacing-8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: $spacing-3;

    .ap-field-content {
      margin-top: $spacing-3;
      font: $typography-body-xs;
      line-height: rem(12px) !important;
      font-weight: $font-weight-1;
      color: $color-text-error;
    }

    .ap-field {
      width: 100%;
    }

    button {
      width: 20%;
    }
  }

  &-table-ScanStatus {
    display: flex;
    align-items: center;

    span:first-child {
      font-size: $spacing-4;
    }

    span:last-child {
      margin-left: $spacing-2;
    }
  }
}

.ap-modal.verificationModal {
  width: rem(540px);
  max-height: rem(380px);
  min-height: rem(252px);
  overflow: hidden;
  .ap-modal-body {
    padding-bottom: 0;
    .verificationModal {
      width: rem(500px);
      overflow: hidden;
    }
    p {
      line-height: rem(12px);
      height: rem(12px);
      font-size: rem(12px);
      padding-left: rem(8px);
    }
  }

  .ap-modal-header {
    width: 100%;
  }

  .verification-message {
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    height: rem(36px);
    &.loading{
      height: rem(12px);
    }
    .message-expired:first-of-type {
      margin-top: 0;
    }
    .message-expired:not(:first-of-type) {
      margin-top: rem(4px);
    }
    &.more-height {
      height: rem(56px);
    }

    .message-expired {
      color: $color-background-danger;
    }
  }

  .bottom-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: rem(40px);
    color: $color-text-light;

    .required {
      margin-left: rem(16px);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: rem(-12px);
        top: $spacing-3;
        width: 0.25rem;
        height: 0.25rem;
        border-radius: $border-radius-1;
        background-color: $color-text-error;
        transition: all 0.3s;
      }
    }

    .required+div {
      display: flex;
    }
  }

  .ap-modal-footer {
    width: 100%;
    margin-top: 0;
    padding-top: rem(20px);
    .ap-modal-footer-customize {
        .ap-button[disabled].ap-button-primary {
          color: rgba($color-text-tertiary-rgb);
        }
      }
  }

  .notification-panel {
    display: flex;
    grid-column-gap: $spacing-4;
    column-gap: $spacing-3;
    margin-top: $spacing-6;
    padding: $spacing-4;
    color: $color-text-body;
    background-color: $color-background-container-alt;
    line-height: 1.5rem;
    border-radius: $border-radius-2;

    .Appkit4-icon.icon-warning-fill.warning-icon {
      color: $color-background-warning !important;
    }
  }
}

.ap-notifications.ap-notifications-bottomLeft {
  .ap-notification-content:has(.ap-loading) {
    .ap-loading {
      margin-right: rem(8px);
      transform: scale(.75) translateY(rem(-8px));
      align-items: unset;
    }
  }
}

.verificationModal-enterPart {
  .ap-field.error .ap-field-wrapper .ap-field-input {
    color: $color-text-heading;
  }

  .ap-field.error .ap-field-title-container .ap-field-title {
    color: $color-text-body;
  }
}

[data-mode='dark'] {
  .verificationModal {
    .message-expired {
      color: $states-error-03 !important;
    }
    .notification-panel {
      background-color: $color-background-hover-selected;
    }
  }
  .ap-modal.verificationModal .ap-modal-footer .ap-modal-footer-customize .ap-button[disabled].ap-button-primary {
    color: unset;
  }
}