@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';

.ap-issue-not-found {
    margin: 0;
    margin-top: rem(16px);
    height: rem(482px);
    background-color: $color-background-container;
    border-radius: $border-radius-3;
    display: flex;
    align-items: center;
    justify-content: center;
  
    .ap-issue-not-found-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: rem(432px);
  
      .ap-issue-not-found-img {
        height: auto;
        max-width: 100%;
      }
      .ap-issue-not-found-subject {
        margin-top: $spacing-5;
        font: $typography-heading-m;
        color: $color-text-heading;
      }
      .ap-issue-not-found-description {
        margin: $spacing-3 0 $spacing-5;
        font: $typography-body;
        color: $color-text-body;
        width: rem(600px);
      }
    }
  }

  .dashboard-wrapper-container .itemFooterContent:has(.ap-issue-not-found) {
    max-width: 100%;
    padding-left: rem(40px);
    padding-right: rem(40px);
    margin-left: 0;
    margin-right: 0;
  }