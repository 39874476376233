@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';
.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &-footer {
    // height: rem(40px);
  }

  .poweredByArea {
    display: flex;
    align-items: end;

    font: $typography-body-s;
    font-weight: $font-weight-1;

    .pb-link {
      color: $color-text-link-primary;
      text-decoration: underline;
    }
  }
}

.ap-footer-content {
  font: $typography-body-s;
  font-weight: $font-weight-1;
  color: $color-text-heading;
  letter-spacing: rem(-0.4px);
}
