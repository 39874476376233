@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';
.app-container {
  .welcome {
    display: flex;
    width: 100%;
    height: calc(100vh - rem(114px));
    justify-content: center;
    align-items: center;
  }

  .content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: calc(100vh - rem(114px));

    .loadingCls {
      width: 100%;
    }

    &.homePage {
      height: 100vh;
      justify-content: unset;
      overflow-y: auto;
      background-color: $color-background-default;
    }

    .sideBarNavigation {
      width: rem(280px);
    }
    .itemContent {
      overflow-y: auto;
      width: calc(100vw - rem(280px));
      padding-left: rem(40px);
      padding-right: rem(40px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100vh - rem(64px));
    }
    .sideBarNavigation.home{
      .itemContent {
        height: unset;
        padding-left: unset;
      }
    }
  }

  .footer {
    // margin-left: rem(40px);
    margin-bottom: $spacing-4;
  }
  .not-first-use-tool-footer {
    margin-top: rem(40px);
    margin-bottom: $spacing-4;
  }
}
