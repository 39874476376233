@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';
.notification {
  display: flex;
  width: 360px;
  height: 72px;
  padding: 4px;
  border-radius: 8px;
  box-shadow: 0px 8px 16px -2px rgba(71, 71, 71, 0.24);
  background-color: #ffffff;
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 2;

  .iconDescPart {
    display: flex;
    padding: 8px 12px;
    height: 64px;
    width: 312px;

    .notification-header-icon {
      width: 16px;
      height: 48px;
      & span {
        width: 16px;
        height: 16px;
        color: #21812d;
      }
    }

    .notification-desc {
      margin-left: 8px;
      width: 264px;
    }
  }

  .notification-close {
    cursor: pointer;
    width: 40px;
    height: 64px;
    padding: 8px 12px;
    & span {
      width: 16px;
      height: 16px;
    }
  }
}

.notification-hide {
  display: none;
}
