@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';
.dashboardContainer {
  .appkitIcon-color {
    color: $color-text-body;
    cursor: pointer;
  }

  .label {
    color: $color-text-heading;
    font-feature-settings:
      'clig' off,
      'liga' off;

    font-size: rem(24px);
    font-style: normal;
    font-weight: $font-weight-2;
    line-height: rem(32px);
    letter-spacing: rem(-0.4px);
    margin-right: $spacing-2;
  }

  .rowStyle {
    padding: 0px !important;
    margin: 0px !important;
  }

  .dashboard-label-row {
    margin: 0px !important;
    .headerTitle {
      margin-top: $spacing-5;
      display: flex;
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-size: rem(36px);
      font-style: normal;
      font-weight: $font-weight-2;
      line-height: rem(42px);
      letter-spacing: rem(-0.4px);
      padding-left: 0px !important;
    }
  }

  .wcagRow {
    margin: 0px !important;
    margin-top: rem(32px) !important;

    .wcagLabelTOoltipContainer {
      display: flex;
      padding-left: 0px !important;
      align-items: center;

      .label {
        color: $color-text-heading;
        font-feature-settings:
          'clig' off,
          'liga' off;

        font-size: rem(24px);
        font-style: normal;
        font-weight: $font-weight-2;
        line-height: rem(32px);
        letter-spacing: rem(-0.4px);
        margin-right: $spacing-2;
      }
    }
  }

  .conformanceRow {
    @media screen and (max-width: 1048px) {
      flex-direction: column;
      align-items: center;

      .col-7,
      .col-5 {
        width: 100% !important;
      }

      .col-5 {
        .function-btn-area {
          padding: $spacing-6;
          align-items: flex-start;
          border-bottom-left-radius: $border-radius-3;
        }
      }

      .col-7 {
        .conformanceBarArea {
          padding: $spacing-6;
          border-top-right-radius: $border-radius-3;
        }
      }
    }

    margin-top: $spacing-5 !important;
    background-color: $color-background-container;
    border-radius: $border-radius-3;

    box-shadow: $box-shadow-1;
    .conformanceBarArea {
      padding: $spacing-6 0px $spacing-6 $spacing-6;
      background-color: $color-background-container;
      border-top-left-radius: $border-radius-3;
      border-bottom-left-radius: $border-radius-3;
    }

    .gap-area {
      background-color: $color-background-container;
      width: 100%;
      height: 100%;
    }

    .function-btn-area {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: end;
      background-color: $color-background-container;
      padding: $spacing-6 $spacing-6 $spacing-6 0px;
      border-top-right-radius: $border-radius-3;
      border-bottom-right-radius: $border-radius-3;
      width: 100%;
      height: 100%;

      & Button {
        width: rem(227px);
      }

      .function-area-btn-first,
      .function-area-btn-last {
        display: flex;
        flex-direction: column;
      }

      .function-area-btn-first {
        .text {
          margin-top: $spacing-3;
          color: $color-text-light;
          font-feature-settings:
            'clig' off,
            'liga' off;

          font-size: rem(14px);
          font-style: normal;
          font-weight: $font-weight-1;
          line-height: rem(20px);
          letter-spacing: rem(-0.2px);
        }
        .ap-button.ap-button-secondary.ap-button-loading.in-loading {
          .ap-button-loading-icon.loading-animation {
            opacity: $opacity-5;
          }
        }
        [disabled] {
          .ap-button-label.ap-font-medium{
            transform: translateX(-12px);
          }
          .btn-name{
            position:relative;
            left: rem(24px);
          }
        }
      }

      .function-area-btn-last {
        margin-top: $spacing-7;
        .text {
          margin-top: $spacing-3;
          color: $color-text-light;
          font-feature-settings:
            'clig' off,
            'liga' off;

          font-size: rem(14px);
          font-style: normal;
          font-weight: $font-weight-1;
          line-height: rem(20px);
          letter-spacing: rem(-0.2px);
        }
      }
    }
  }

  .highRecommandLabelsRow {
    margin-top: $spacing-7 !important;
  }

  .highRecommandContentRow {
    .text-container {
      margin-top: $spacing-3;
    }

    .text,
    .numbertext {
      font-feature-settings:
        'clig' off,
        'liga' off;

      font-weight: $font-weight-2;
      font-style: normal;
    }

    .left-numbertext {
      margin-left: $spacing-2;
      color: $color-text-body;
      font-feature-settings:
        'clig' off,
        'liga' off;

      font-size: rem(16px);
      font-style: normal;
      font-weight: $font-weight-1;
      line-height: rem(24px);
      letter-spacing: rem(-0.4px);
    }

    .text {
      color: $color-text-heading;
      font-size: rem(24px);
      line-height: rem(32px);
      letter-spacing: rem(-0.4px);
    }

    .numbertext {
      margin-top: $spacing-5;
      margin-bottom: $spacing-5;
      color: $color-text-heading;
      font-size: rem(48px);
      line-height: rem(48px);
      letter-spacing: rem(-0.8px);
    }

    .automatedscan-text,
    .manualreview-text {
      color: $color-text-body;
      font-feature-settings:
        'clig' off,
        'liga' off;

      font-size: rem(16px);
      font-style: normal;
      font-weight: $font-weight-2;
      line-height: rem(24px);
      letter-spacing: rem(-0.4px);
    }

    .icon-space {
      margin-right: $spacing-2;
    }
    .icon-space-right {
      margin-right: $spacing-2;
    }

    .highLabelArea {
      margin-top: $spacing-8 !important;
      display: flex;
      align-items: center;
    }

    .highContentArea {
      margin-top: $spacing-5;
      padding: $spacing-6;
      background-color: $color-background-container;
      border-radius: $border-radius-3;
      box-shadow: $box-shadow-1;

      .criticalSeriousPart {
        display: flex;
        .critical {
          border-right: rem(1px) solid $neutral-04;
          width: 50%;
        }

        .serious {
          padding-left: $spacing-6;
          width: 50%;
        }

        @media screen and (max-width: 1048px) {
          flex-direction: column;
          .text-container {
            text-wrap: nowrap;
          }
          .critical {
            border-right: none;
          }
          .serious {
            padding-left: 0px;
            margin-top: rem(32px);
          }
        }
      }

      .high-desc {
        display: flex;
        align-items: flex-start;
        background-color: $color-background-default;
        margin-top: $spacing-5;
        color: $color-text-body;
        font-feature-settings:
          'clig' off,
          'liga' off;

        font-size: rem(16px);
        font-style: normal;
        font-weight: $font-weight-1;
        line-height: rem(24px);
        letter-spacing: rem(-0.4px);
        padding: $spacing-4 $spacing-5;
        border-radius: $border-radius-3;

        .highDescContainer {
          display: flex;
          align-items: flex-start;
          .outlineIcon {
            margin-right: $spacing-3;
          }
        }
      }
    }

    .lowPart {
      margin-top: $spacing-8;
      .lowLabelArea {
        display: flex;
        align-items: center;
      }
      .lowContentArea {
        background-color: $color-background-container;
        border-radius: $border-radius-3;
        padding: $spacing-6;
        display: flex;
        margin-top: $spacing-5;

        box-shadow: $box-shadow-1;

        .text-container {
          margin-top: $spacing-3;
        }

        .text,
        .numbertext {
          font-feature-settings:
            'clig' off,
            'liga' off;

          font-weight: $font-weight-2;
          font-style: normal;
        }

        .left-numbertext {
          margin-left: $spacing-2;
          color: $color-text-body;
          font-feature-settings:
            'clig' off,
            'liga' off;

          font-size: rem(16px);
          font-style: normal;
          font-weight: $font-weight-1;
          line-height: rem(24px);
          letter-spacing: rem(-0.4px);
        }

        .text {
          color: $color-text-heading;
          font-size: rem(24px);
          line-height: rem(32px);
          letter-spacing: rem(-0.4px);
        }

        .numbertext {
          margin-top: $spacing-5;
          margin-bottom: $spacing-5;
          color: $color-text-heading;
          font-size: rem(48px);
          line-height: (48px);
          letter-spacing: rem(-0.8px);
        }

        .automatedscan-text,
        .manualreview-text {
          color: $color-text-body;
          font-feature-settings:
            'clig' off,
            'liga' off;

          font-size: rem(16px);
          font-style: normal;
          font-weight: $font-weight-2;
          line-height: rem(24px);
          letter-spacing: rem(-0.4px);
        }

        .icon-space {
          margin-right: $spacing-2;
        }
        .icon-space-right {
          margin-right: $spacing-2;
        }

        .moderate {
          border-right: rem(1px) solid $neutral-04;
          width: 50%;
        }

        .minor {
          padding-left: $spacing-6;
          width: 50%;
        }
      }
    }

    .recommandContentCol {
      margin-left: rem(32px) !important;
    }

    .recommandLabelArea {
      margin-top: $spacing-8 !important;
      margin-left: rem(32px);
      .title {
        color: $color-text-heading;
        font-feature-settings:
          'clig' off,
          'liga' off;

        font-size: rem(24px);
        font-style: normal;
        font-weight: $font-weight-2;
        line-height: rem(32px);
        letter-spacing: rem(-0.4px);
      }
    }

    .recommandContentArea {
      margin-left: rem(32px);
      background-color: $color-background-container;
      border-radius: $border-radius-3;
      padding: $spacing-6;
      margin-top: $spacing-5;

      box-shadow: $box-shadow-1;

      .recommTooltip {
        display: flex;
        align-items: center;
      }

      .desc {
        margin-top: $spacing-3;
        color: $color-text-body;
        font-feature-settings:
          'clig' off,
          'liga' off;

        font-size: rem(16px);
        font-style: normal;
        font-weight: $font-weight-1;
        line-height: rem(24px);
        letter-spacing: rem(-0.4px);
      }

      .reBtn-container {
        margin-top: $spacing-5;
      }

      .reco-desc {
        display: flex;
        align-items: flex-start;
        background-color: $color-background-default;
        margin-top: $spacing-5;
        color: $color-text-body;
        font-feature-settings:
          'clig' off,
          'liga' off;

        font-size: rem(16px);
        font-style: normal;
        font-weight: $font-weight-1;
        line-height: rem(24px);
        letter-spacing: rem(-0.4px);
        padding: $spacing-4 $spacing-5;
        border-radius: $border-radius-3;

        .recomDescContainer {
          display: flex;
          align-items: flex-start;
          .recoDescIcon {
            margin-right: $spacing-3;
          }
        }
      }
    }

    @media screen and (max-width: 1048px) {
      display: flex;
      flex-direction: column;
      .text-container {
        text-wrap: nowrap;
      }
      .col-7,
      .col-5 {
        width: 100% !important;
      }

      .col-5 {
        .recommandLabelArea,
        .recommandContentArea {
          margin-left: 0px;
        }
      }

      .col-7 {
        .lowPart {
          .lowContentArea {
            display: flex !important;
            flex-direction: column !important;

            .moderate {
              border-right: none;
            }
            .minor {
              margin-top: rem(32px);
              padding-left: 0px;
            }
          }
        }
      }
    }
  }

  .conformancePartContent-productPendingScanPage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $spacing-6;

    &-textArea {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      letter-spacing: rem(-0.4px);
      h2 {
        font: $typography-heading-s;
        font-weight: $font-weight-2;
      }

      &-span {
        font: $typography-body;
        font-weight: $font-weight-1;
        margin-top: $spacing-3;
        text-align: center;
      }

      &-btnArea {
        margin-top: $spacing-5 !important;
      }
    }
    &-imgArea {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 80%;
        height: 80%;
      }
    }

    .normal {
    }

    .responsive {
      display: none;
    }

    @media screen and (max-width: 1048px) {
      .normal {
        display: none !important;
      }

      .responsive {
        display: block;
      }
    }
  }
}

.dashboardContainerLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - rem(156px));
}

[data-mode='dark'] {
  .dashboardContainer .highRecommandContentRow .highContentArea .high-desc {
    background-color: $color-background-hover-selected;
  }
  .dashboardContainer .highRecommandContentRow .recommandContentArea .reco-desc {
    background-color: $color-background-hover-selected;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
