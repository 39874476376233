@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';

@mixin iconStyle($width, $height, $src, $color) {
    display: block;
    width: $width;
    height: $height;
    mask-image: url($src);
    -webkit-mask-image: url($src);
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-position: center;
    background-color: $color;
  
    &.icon-black {
      background-color: $color-text-body;
    }
  }

  .tab-suggestions {
    // padding: $spacing-4;
    // margin-top: $spacing-4;
    
    .tab-suggestions-header, .tab-suggestions-comment {
        display: flex;
        align-items: center;
        font-size: rem(12px);
        line-height: rem(14px);
        height: rem(14px);
       span {
        color: $color-text-heading;
        &.detail {
          font-weight: $font-weight-1!important;
        }
        &.assist {
            // margin-left: rem(4px);
            color: #D93954!important;
            font-weight: $font-weight-1!important;
        }
      }
    }

    .tab-suggestions-detail {
      margin-top: $spacing-4;
      background-color: #ECEEF3;
      padding: rem(12px);
      border-radius: $border-radius-3;
       p {
        font-size: $typography-body;
        font-weight: $font-weight-1;
        line-height: $spacing-7;
        letter-spacing: rem(-0.4px);
        color: $color-text-body;
        // margin-top: rem(8px);
      }
      // .sample-code{
      //   margin-top: $spacing-4;
      // }
      .sample-code.markdown {
        font-size: rem(14px);
        font-weight: $font-weight-1;
        line-height: rem(20px);
        letter-spacing: rem(-0.2px);
        h3:not(:first-of-type),h2:not(:first-of-type)
        ,h4:not(:first-of-type),h5:not(:first-of-type),
        h6:not(:first-of-type) {
          margin-top: rem(20px);
        }
        h2+h3,p+h2:first-of-type,p+h3:first-of-type,
        p+h4:first-of-type,p+h5:first-of-type,
        p+h6:first-of-type{
          margin-top: rem(20px);
        }
        .simple-code.markdown {
          margin-top: rem(12px);
          margin-bottom: rem(12px);
        }
      }
      .sample-code .simple-code-codePart .sample-code-single-wrapper .sample-code-wrapper code{
        color: #9AA4BE;
      }
    }

    .tab-suggestions-sampleCode {
      margin-top: $spacing-4;
    }

    .tab-suggestions-lightPart {
      margin-top: $spacing-3;
    }
  }

  .icon-ai {
    @include iconStyle(16px, 16px, "../../assets/icons/AI.svg", $data-rose);
    line-height: rem(16px);
    height: rem(16px);
  }

  [data-mode='dark'] {
    .tab-suggestions .tab-suggestions-detail{
      background-color: $color-background-hover;
    }
    .tab-suggestions-lightPart {
      background-color: $color-background-hover!important;
    }
    .tab-suggestions-header, .tab-suggestions-comment {
     span {
      &.assist {
          color: #F1BAC3!important;
      }
    }
    .icon-ai {
      background-color: #F1BAC3!important;
    }
  }
  }