@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';

.forbiddenPage {
  background-color: $color-background-container;
  border-radius: $border-radius-3;
  padding: $spacing-8 rem(72px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .fb-bckgImg {
    width: rem(250px);
    height: rem(250px);
  }
  .fb-boldText {
    margin-top: $spacing-5;
    font: $typography-heading-m;
    font-weight: $font-weight-2 !important;
    letter-spacing: rem(-0.4px);
    color: $color-text-heading;

    h2 {
      font-weight: $font-weight-2 !important;
    }
  }
  .fb-descText {
    width: rem(516px);
    text-align: center;
    margin-top: $spacing-3;
    font: $typography-body;
    font-weight: $font-weight-1;
    letter-spacing: rem(-0.4px);
    color: $color-text-body;

    a {
      text-decoration: underline;
      color: $color-text-link-primary;
    }
  }
  .fb-teamMembers {
    margin-top: $spacing-5;
  }

  .fb-btn {
    margin-top: $spacing-7;
  }
}

.teamMemeberInfoBoard {
  display: flex;
  align-items: center;
  width: rem(430px);
  height: rem(64px);

  .teamMemeberInfoBoard-avatar {
    margin-right: $spacing-3;
  }
  .teamMemeberInfoBoard-userInfo {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-right: $spacing-3;

    span:first-child {
      font: $typography-body;
      letter-spacing: rem(-0.4px);
      color: $color-text-body !important;
      font-weight: $font-weight-2;
    }

    span:last-child {
      font: $typography-body;
      letter-spacing: rem(-0.4px);
      font-weight: $font-weight-1 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $color-text-light;
    }
  }
}
