@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';

@mixin iconStyle($width, $height, $src, $color) {
  display: block;
  width: $width;
  height: $height;
  mask-image: url($src);
  -webkit-mask-image: url($src);
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
  background-color: $color;
  margin: rem(4px);
  &.icon-black {
    background-color: $color-text-body;
  }
}

.manualPage {
  margin-top: rem(64px);
  // height: 100vh;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  background-color: $color-background-default;
  // overflow-y: hidden;
  margin-left: 0;
  margin-right: 0;
  .breadcrumbPart{
    margin-top: rem(16px);
  }
  .option-group{
    .ap-radio-group{
      display: flex;
      flex-direction: row;
      gap: rem(24px);
    }
  }
  &-content {
    padding-bottom: $spacing-6;
  }
  .ap-accordion .ap-accordion-content .ap-accordion-wrapper {
    padding-bottom: 0;
    .template-container-title {
      color: $color-text-heading;
      font-weight: $font-weight-2;
    }
  }
.manualPage-content-section{
  // height: 100%;
  &.loading{
    height: calc(100vh - rem(317px));
  }
  .manualPage-content {
    padding-bottom: 0;
    // overflow-y: hidden;
    &.normal {
      display: flex;
      // height: 100%;
      .col-3{
        // max-height: calc(100vh - rem(220px));
        width:25.589%;
        position: sticky;
        &.fixed{
          position: fixed;
          max-width: rem(348px);
        }
        top: rem(64px);
      }
      .col-9.manual9{
        height:100%;
        padding-left: 0;
        padding-right: 0;
        width:calc(100% - 25.589%);
        &.fixed{
          margin-left: rem(348px);
        }
      }
    }

    &.smallTemp {
      display: none;
      height: 100%;
      .col-4{
        // max-height: calc(100vh - rem(220px));
        width:25.589%;
        position: sticky;
        &.fixed{
          position: fixed;
          max-width: rem(348px);
        }
        top: rem(64px);
      }
      .col-8.manual9 {
        height:100%;
        padding-left: 0;
        padding-right: 0;
        width:calc(100% - 25.589%);
        // height: calc(100% + rem(10px));
        .right-side-section{
          height: 100%;
        }
        &.fixed{
          margin-left: rem(348px);
        }
      }
    }

    @media screen and (max-width: 1440px) {
      &.normal {
        display: none;
      }

      &.smallTemp {
        display: flex;
      }
    }
  }
}

  .itemContent {
    height: calc(100vh - rem(64px));
  }

  .ap-list.ap-list-bordered.custom-item-list {
    // background-color: #f9f9f9;
    // max-height: rem(968px);
    overflow-y: auto;
    margin-right: rem(-16px);
    padding-right: rem(16px);
    height: calc(100% - rem(16px));
    .ap-list-item {
      // border-bottom: 1px solid rgb(125, 125, 125, 0.3);
      // font-weight: 500;
      padding: rem(8px);
      &:first-child {
        margin-top: 0;
      }

      &.selected {
        background-color: $color-background-selected;
        border-radius: $border-radius-2;
        .primary-text {
          font-weight: $font-weight-2;
        }
      }
      &.disabled {
        pointer-events: none;
      }
    }
  }

  .ap-panel-with-extra-container {
    // overflow-y: auto;
    padding-right: 1rem !important;
    margin-right: -1rem;
    display: block;
    height: calc(100% + rem(12px));
    &.ap-container {
      padding-left: 0px;
      padding-right: 0px;
    }

    .ap-pattern-section-content {
      // height: calc(100vh - rem(64px));
      &.manul-issue-list{
        position: relative;
        .ap-accordion-toggle {
          padding: 0;
        }
        .ap-accordion:hover{
          background-color: unset;
        }
        .editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-expandPart {
          margin-top: 0;
          label.title-text {
            font: $typography-body;
            font-weight: $font-weight-2;
          }
        }
      }
      .ap-panel-head.has-extra {
        padding: rem(20px) rem(30px);
        display: inline-flex;
        align-items: center;
      }
          .operation-box {
            display: inline-flex;
            padding-top: $spacing-4 0;
            margin-top: rem(12px);
            // &.show {
            //   display: inline-flex;
            // }
            @media screen and (max-width: 900px) {
              flex-wrap: wrap;

              .responsive-cls {
                margin-left: 0px !important;
                margin-top: $spacing-6;
              }
            }
          }

          .ap-panel.issue-list-panel {
            height: rem(58px);
            line-height: rem(56px);
            border: 1px solid #d1d1d1;
            border-radius: rem(8px);
            padding: rem(12px) rem(20px) rem(12px) rem(20px);
            box-shadow: none;
            .ap-panel-body {
              line-height: rem(32px);
            }
            &:hover {
              cursor: pointer;
            }

            .ap-panel-body {
              padding: 0;
              .ap-panel-content {
                padding: 0px;

                .issue-details-box {
                  &.normal {
                    display: flex;
                  }

                  &.smallTemp {
                    display: none;
                  }

                  @media screen and (max-width: 1440px) {
                    &.normal {
                      display: none;
                    }

                    &.smallTemp {
                      display: flex;
                      // flex-direction: column;
                      font-weight: $font-weight-2;
                      justify-content: space-between;
                      .issue-status-update{
                        margin-top: 0!important;
                        display: flex;
                        position: relative;
                        top: rem(-2px);
                        // transform: translateY(-4px);
                        position: relative;
                        top: rem(-4px);
                        .option-title{
                          color: $color-text-link-primary;
                        }
                        .ap-button-dropdown.open{
                          .ap-option-item:last-of-type{
                            color: $color-background-danger;
                          }
                        }
                        // .icon-horizontal-more-outline:hover{
                        //   background-color: rgba($color-background-primary-rgb, $opacity-3);
                        // }
                        .custom-node{
                          width: rem(40px);
                          height: rem(40px);
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          border-radius: $border-radius-2;
                          cursor: pointer;
                          &:hover{
                            background-color: rgba($color-background-primary-rgb, $opacity-3);
                          }
                        }
                      }
                      // .ap-buttons-wrapper {
                      //   transform:translateY(4px);
                      // }
                      .issue-title {
                        font-size: rem(16px);
                        @media screen and (min-width: 768px) and (max-width: 1080px) {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            max-width: rem(200px);
                          }
                      }

                      .issue-link {
                        color: $color-text-link-primary;
                        text-decoration: underline;

                        &:hover {
                          cursor: pointer;
                        }
                      }
                    }
                  }

                  &.normal {
                    justify-content: space-between;
                    font-weight: $font-weight-2;

                    .issue-title {
                      font-size: rem(16px);
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      max-width: rem(700px);
                    }
                    .issue-status-update{
                      display: flex;
                      position: relative;
                      top: rem(-2px);
                      .option-title-open-issue-btn{
                        transform: translateY(rem(-4px));
                      }
                      .option-title{
                        color: $color-text-link-primary;
                      }
                      .ap-button-dropdown.open{
                        .ap-option-item:last-of-type{
                          color: $color-background-danger;
                        }
                      }
                      // .icon-horizontal-more-outline:hover{
                      //   background-color: rgba($color-background-primary-rgb, $opacity-3);
                      // }
                      .custom-node{
                        width: rem(40px);
                        height: rem(40px);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: $border-radius-2;
                        cursor: pointer;
                        &:hover{
                          background-color: rgba($color-background-primary-rgb, $opacity-3);
                        }
                      }
                      .ap-buttons-wrapper{
                        position: relative;
                        top: rem(-2px);
                      }
                    }
                    .issue-link {
                      color: $color-text-link-primary;
                      text-decoration: underline;

                      &:hover {
                        cursor: pointer;
                      }
                    }
                  }
                }
              }
            }
            &.ap-mb-spacing-3.ap-panel-bordered:last-of-type {
              .ap-buttons-wrapper .custom + div.ap-button-dropdown.align-right {
                bottom: 50%;
                transform: translateY(-2rem);
                top: unset;
              }
            }
          }
        
      

      .ap-panel-extra {
        top: 20px;
        right: 30px;

        @media screen and (max-width: 1440px) {
          top: 60px;
          right: 0px;
          left: 30px;
        }
        @media screen and (max-width: 900px) {
          top: 90px;
          right: 0px;
          left: 30px;
        }
        @media screen and (min-width: 900px) and (max-width: 1440px) {
          top: 70px;
          right: 0px;
          left: 30px;
        }
      }
    }
  }
}

.issueDetails {
  display: none;
  color: $color-text-light;
  margin-left: rem(24px);
  margin-right: rem(12px);
  &.show {
    display: block;
    padding-top: rem(12px);
  }

  .add-issue-btn {

    display: none;

    &.showAdd {
      display: block;
    }
  }
}

.ap-list.ap-list-bordered.custom-item-list {
  width: auto !important;
  display: block !important;
}

.ap-accordion.custom-acc {
  &:not(:last-child) {
    // margin-bottom: 20px !important;
  }

  .ap-accordion-toggle .ap-accordion-title {
    height: auto;
    white-space: normal;
  }

  .ap-accordion-content.ap-accordion-cursor-default {
    padding-top: 8px;
    padding-right: $spacing-4;
    display: none;
  }

  &.expanded {
    .ap-accordion-content.ap-accordion-cursor-default {
      display: block;
    }
  }
}

.completed.icon-success-fill {
  color: $color-text-success;
}

.ap-modal-wrapper {
  .create-issue-modal.ap-modal {
    width: rem(800px) !important;
    max-height: rem(650px);
    overflow: visible;
    margin-top: 0;
    .ap-modal-header {
      position: sticky;
      top: 0;
      // z-index: 2001;
    }

    .ap-modal-body {
      overflow: auto;
      // margin-top: 20px;
      padding: 0 rem(20px) rem(20px) rem(20px);
      // border-bottom: 1px solid #e8e8e8;
    }

    .ap-modal-footer {
      // height: 60px;
      display: flex;
      justify-content: flex-end;
      .list-modalFooter-descArea {
        position: relative;
        margin-left: $spacing-3;
        display: flex;
        align-items: center;
        span {
          position: relative;
          font-size: rem(14px);
          font-weight: $font-weight-1;
          line-height: rem(16px);
          letter-spacing: rem(-0.4px);
          color: $color-text-light;
        }
        span::before {
          content: '';
          background-color: #c52b19;
          position: absolute;
          left: rem(-8px);
          top: 50%;
          display: inline-block;
          width: $spacing-2;
          height: $spacing-2;
          border-radius: $spacing-1;
          transform: translateY(-50%);
        }
      }
    }

    
  }

  .uploaded-image-box {
    display: none;

    &.show {
      display: block;
      position: relative;
    }

    .comment-title {
      color: $color-text-light;
      font-size: $typography-text-size-3;
      letter-spacing: $letter-spacing-1;
      line-height: $typography-line-height-3;
      position: absolute;
      top: rem(15px);
      left: $spacing-3;
      transition: all $transition-0;
      z-index: 2;
      cursor: default;

      display: flex;
      align-items: center;
      height: $spacing-7;
      background-color: $color-background-container;
      width: calc(100% - $spacing-3 - $spacing-3);

      &.comment-title-small {
        font-size: $typography-text-size-1;
        line-height: rem(12px);
        top: rem(2px);
      }
    }

    // .uploaded-image-content {
    //   width: 100%;
    //   height: 100%;
    // }

    .ap-text-editor-container.ck-editor {
      box-shadow: none;

      .ck-toolbar.ck-toolbar_grouping.ck-reset_all.ck-rounded-corners {
        display: none;
      }

      .ck.ck-editor__editable_inline {
        margin-top: $spacing-5;
        height: rem(200px);
        box-shadow: inset $shadow-border;
        border-radius: $border-radius-2;
        padding-left: $spacing-3;
        padding-top: $spacing-7;
        transition: box-shadow $transition-0;

        &.ck-content .mention {
          background: transparent;
        }

        &:focus-within {
          box-shadow:
            inset 0 0 0 rem(1px) $color-background-primary,
            0 0 0 rem(4px) rgba($color-background-primary-rgb, $opacity-3);
        }

        .language-plaintext {
          white-space: pre-wrap;
        }
      }

      pre {
        overflow: auto;
      }

      .ck-toolbar {
        .ck-toolbar__items {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    img {
      width: 80%;
    }
  }
}
.create-issue-modal-content {
  color: $color-text-heading;
  .issue-type-desc-value {
    font-weight: $font-weight-2;
  }
  .issue-type-header {
    font-size: rem(16px);
    font-weight: 400;
    line-height: rem(24px);
    text-align: left;
    .ap-radio-group{
      .ap-radio-container:nth-child(1){
        margin-bottom: rem(8px);
      }
    }
    .issue-type-desc-value {
      position: relative;
      color: $color-text-heading;
      font-weight: $font-weight-2;
      &::before {
        content: '';
        background-color: #c52b19;
        position: absolute;
        left: rem(92px);
        display: inline-block;
        width: $spacing-2;
        height: $spacing-2;
        border-radius: $spacing-1;
      }
    }

    .issue-type-item.impacted-pages {
      position: relative;
      .red-dot.no-top::before{
        content: '';
        background-color: #c52b19;
        position: absolute;
        left: rem(116px);
        top: unset;
        display: inline-block;
        width: $spacing-2;
        height: $spacing-2;
        border-radius: $spacing-1;
      }
    }

    .issue-type-desc-opertaion {
      display: flex;
      // justify-content: flex-end;
    }
  }

  .ap-field-demo-wrapper {
    width: 100%;
  }

  .page-link-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search-field {
      margin-left: rem(-8px);
      // width: rem(500px);
      height: rem(48px);
      position: relative;
      width: rem(720px);
      .ap-search-container {
        margin-top: -8px;
        z-index: 2001;
        // @media screen and (max-width: 798px) {
        //   width: rem(638px)!important;
        // }
      }
    }

    .btn-box {
      display: inline-flex;
      align-items: center;
    }
  }

  .impacted-url-list {
    display: none;

    &.showUrlList {
      display: block;
    }

    .url-list-head {
      display: flex;
      justify-content: space-between;
      font-weight: $font-weight-2;
      // border-top: 1px solid rgb(125, 125, 125, 0.3);
      .url-list-head-left{
        color: $color-text-light;
        font-weight: 400;
      }
    }
    .icon-delete-outline {
      cursor: pointer;
    }
  }

  .url-list-content {
    display: block;

    .content-body-common-box {
      .content-body-common-title {
        font-weight: $font-weight-2;
        font-size: rem(16px);
        span.red-dot {
          position: relative;
        }

        span::after {
          content: '';
          background-color: #c52b19;
          position: absolute;
          right: -0.5rem;
          top: $spacing-3;
          display: inline-block;
          width: $spacing-2;
          height: $spacing-2;
          border-radius: $spacing-1;
        }
        span.red-dot.no-top::after {
          top: unset;
        }
      }

      .ap-field-demo-wrapper {
        position: relative;
        .ap-field.textarea.ap-ml-spacing-3 {
          margin-left: 0px !important;
        }
        // .ap-field .ap-field-title-container.required::before{
        //   display: none;
        // }
        .ap-field .ap-field-title-container.required:before {
          content: "";
          position: absolute;
          right: calc(-1* var(--spacing-3));
          top: var(--spacing-1);
          width: .25rem;
          height: .25rem;
          border-radius: var(--border-radius-1);
          background-color: var(--color-text-error);
          transition: all .3s;
      }
    }

      .number-status {
        font-size: rem(12px);
        color: $color-text-body;
      }

      .content-body-common-desc {
        font-size: rem(14px);
        font-weight: 400;
        line-height: rem(16px);
        text-align: left;
      }
    }

    .upload-part {
      height: rem(104px);
      .ap-fileupload-footer-delete-attachment {
        display: none;
      }
    }
  }
}
.save-btn-box {
  display: flex;
  justify-content: flex-end;
}

.ap-modal-footer-customize {
  width: 100%;
  // border-top: 1px solid #e8e8e8;
  justify-content: flex-end;

  .url-list-footer {
    // border-top: 1px solid #e8e8e8;
    width: 100%;
    display: flex !important;
    justify-content: space-between !important;
    .footer-button-group{
      display: flex;
      .sr-only{
        opacity: 0;
        width: 0;
        height: 0;
      }
    }
  }
}

.file-list-error {
  align-items: center;
  margin-right: auto;
  background-color: #f3d4d1;
  height: rem(48px);
  line-height: rem(48px);
  vertical-align: middle;
  display: none;
  border-radius: $border-radius-2;
  height: rem(48px);
  padding-left: $spacing-3;
  position: relative;
  color: $color-text-body;

  &.show {
    display: block;
  }

  .Appkit4-icon {
    color: $states-success-06;
  }
}

.file-list {
  margin-top: $spacing-2;
  margin-bottom: $spacing-6;

  .del-all {
    font-size: 1rem;
    font-weight: $font-weight-2;
    line-height: 1.5rem;
    letter-spacing: -0.025rem;
    color: $color-text-primary;
    cursor: pointer;
    display: none;

    &.show {
      display: block;
    }
  }

  .file-item {
    margin-bottom: $spacing-2;

    .file-loaded {
      align-items: center;
      background-color: transparent;
      border-radius: $border-radius-2;
      display: flex;
      height: 3rem;
      justify-content: space-between;
      position: relative;

      &:hover {
        background-color: $color-background-selected;
        cursor: pointer;
      }

      .file-name-part {
        display: flex;
        align-items: center;

        .file-upload-span {
          width: 23.125rem;
          display: flex;
          align-items: center;
          margin-left: $spacing-2;
          margin-right: auto;
        }
      }

      .ap-fileupload-success {
        width: $width-6;
        height: $height-5;
        padding: $spacing-3;
        border-radius: $border-radius-2;
        display: none;

        &.show {
          display: block;
        }

        .Appkit4-icon {
          color: $states-success-06;
        }
      }
    }
  }
}

.ap-accordion-title-extra.ap-accordion-title {
  .custom-issue-title-section {
    .custom-issue-title {
      display: flex;
      gap: rem(2px);
      width: 100%;
      justify-content: flex-start;
      .icon-success-fill {
        color: #8C8C8C !important;
      }
      .completed.icon-success-fill {
        color: $color-text-success !important;
      }
      .incomplete-icon {
        @include iconStyle(16px, 16px, "../../assets/icons/success.incomplete.svg", $color-text-success);
      }
    }
    .ap-badge span {
      font-weight: $font-weight-1;
      color: $color-text-body;
    }
  }
}

.smallTemp {
  .col-8 {
    .ap-panel-head-title {
      height: 100%;
      white-space: unset;
      text-overflow: unset;
    }
  }
}

.manual-review-nav{
  display: flex;
  flex-direction: column;
  height: calc(100% - rem(68px));
  .manual-review-list{
    background-color: $color-background-container;
    padding: rem(20px);
    height: 100%;
    border-radius: rem(4px);
    .manual-review-list-title{
      margin-bottom: rem(12px);
      color: $color-text-light;
    }
  }
  .guideline-wrapper {
    overflow-y: auto;
    margin-right: -1rem;
    padding-right: 1rem;
    height: calc(100% - 16px);
    .category-list {
      background-color: $color-background-container;

      .category-list-title {
        background-color: $color-background-default;
        border-radius: rem(4px);
        height: rem(28px);
        line-height: rem(28px);
        padding: rem(8px) rem(4px) rem(8px) rem(16px);
        display: flex;
        align-items: center;
        color:$color-text-body;
      }
    }
  }
.ap-list.ap-list-bordered.custom-item-list{
  box-shadow: unset;
  padding-top: $spacing-3!important;
  padding-left: 0!important;
  // padding-right: 0!important;
 }
.ap-dropdown+.category-list{
    padding-top: rem(16px);
}
}
.list-section-item{
  display: flex;
  flex-direction: column;
  color: $color-text-body;
  .list-section-item-title{
    display: flex;
    align-items: center;
    gap: rem(4px);
    // align-items: center;
    .primary-text {
      word-break: normal;
    }
  }
  .primary-text-count {
    margin-left: rem(32px);
    line-height: rem(24px);
    height: rem(24px);
    color: $color-text-light;
  }
  .icon-success-fill {
    color: #8C8C8C !important;
    &.completed {
      color: $color-text-success !important;
    }
  }
  .incomplete-icon{
    @include iconStyle(16px, 16px, "../../assets/icons/success.incomplete.svg", $color-text-success);
  }
}

.ap-pattern-section-content{
  // max-width: calc(100% - rem(16px));
  padding: $spacing-4;
  border-radius: $border-radius-2;
  // box-shadow: $box-shadow-1;
  background-color: $color-background-container;
  &:not(:last-child) {
    margin-bottom: rem(12px);
  }
  .ap-accordion .ap-accordion-toggle{
    padding-left: 0;
    padding-right: 0;
    .ap-accordion-title-extra.ap-accordion-title .custom-issue-title{
      justify-content: flex-start;
    }
  }
  .ap-accordion.expanded {
    .ap-accordion-content{
      overflow: unset;
    }
  }
  .ap-accordion:hover {
    background-color: unset;
  }
  .ap-accordion .ap-accordion-toggle {
    align-items: unset;
  }
}
.trigger-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.ap-pattern-filter-popup {
  background-color: var(--color-background-container);
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  padding: .75rem;
  box-shadow: var(--box-shadow-3);
}
.popup-area {
  .ap-button {
      // height: unset;
      padding: rem(4px);
      .Appkit4-icon {
        margin-right: 0;
        height: rem(16px);
        line-height: rem(16px);
      }
    }
  .ap-button-label.ap-font-medium{
    height: rem(16px);
    line-height: rem(16px);
  }
}
.filter-content{
  margin-bottom: rem(12px);
  &.empty {
      min-width: rem(200px);
      min-height: rem(88px);
  }
  .filter-list-row{
      display: flex;
      // padding: rem(12px);
      height: rem(32px);
      line-height: rem(32px);
      justify-content: space-between;
      margin-bottom: rem(4px);
      align-items: center;
      &.last {
        margin-bottom: 0;
      }
      &:focus-visible{
          outline: auto rem(5px) -webkit-focus-ring-color;
          box-shadow: none;
      }
      .ap-checkbox-container{
          width: rem(101px);
          .Appkit4-icon.icon-checkbox-deselected-fill+.icon-checkbox-backdrop-outline{
            transform: translateY(-18px);
          }
          .ap-checkbox-label{
            height: rem(32px);
            line-height: rem(32px);
          }
      }
      .secondary-text{
          margin-left: rem(8px);
          line-height: rem(24px);
          color: $color-text-light;
      }
      &.moved:not(.disabled) {
          background-color: $color-background-hover;
          box-shadow: 0 0 0 0.0625rem $color-background-tertiary;
      }
  }
}
.filter-footer{
  display: flex;
  gap: rem(8px);
  justify-content: center;
  width:100%;
  .ap-button{
    width: rem(96px);
    display: flex;
    align-items: center;
  }
}
.content-area {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // max-width: calc(100% - rem(16px));
    .ap-button.ap-button-secondary.searchButtonContainer-narrowFilter_iconPart{
      width: rem(32px);
      height: rem(32px);
      display: flex;
      align-items: center;
    }
    // .ap-button {
    //   padding-left: rem(8px);
    //   padding-right: rem(8px);
    // }
  .mark-all-toggle-switch{
    width: rem(390px);
  }
  .filterItemsPart {
    &.show {
      display: block;
    }
    &.hide {
      display: none;
    }
    .filter-title{
      height: rem(32px);
      line-height: rem(32px);
      padding-left: 0;
      padding-top: 0;
      margin-bottom: rem(4px);
      font-weight: $font-weight-2;
      margin-left: rem(4px);
    }
    width: rem(224px);
    box-shadow: $box-shadow-3;
    background-color: $color-background-container;
    position: absolute;
    top: $spacing-8;
    right: 0;
    padding: rem(12px);
    border-radius: $border-radius-3;
    z-index: 99;

    &-content {
      max-height: rem(450px);
      overflow-y: auto;
      scrollbar-width: none;
    }

    &-btnParts {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: rem(18px);
      .ap-button{
        width:rem(96px);
      }
    }
    .filter-footer{
      margin-top: rem(12px);
    }
  }
}
// .recomDescContainer{
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
// }
.manual-suggestions-lightPart {
  padding: $spacing-5;
  background-color: $color-background-container-alt;
  border-radius: rem(8px);
  display: flex;
  align-items: center;
  font-size: rem(16px);
  line-height: rem(24px);
  height: rem(48px);
  display: flex;
  align-items: center;
  width: 100%;
  color: $color-text-body;
  .lightIcon {
    width: $spacing-5;
    height: $spacing-7;
    line-height: $spacing-7;
  }
  span:nth-child(2) {
    font-weight: $font-weight-1;
  }
}

.data-list-filter {
  display: flex;
  align-items: center;
  &.has-tag {
    height: rem(32px);
    line-height: rem(32px);
    margin-bottom: rem(12px);
  }
.data-list-header-right-option,.data-list-header-right-filter{
  display: flex;
  align-items: center;
  .tags-panel{  
      display: flex;
      align-items: center;
      .ap-tag:not(:last-child){
          margin-right: rem(16px);
      }
  }
  .ap-tag.ap-tag-sm .Appkit4-icon.icon-close-outline{
    display: flex;
    align-items: center;
    padding: 0;
    background-color: transparent;
    width: .75rem;
    height: .75rem;
    transform: translateY(rem(-2px));
    cursor: pointer;
    &:before {
      content: "\e97a";
  }
}
 }
 .tags-panel-border{
  background-color: #D9D9D9;
  height: rem(32px);
  width: rem(1px);
  margin-left:rem(8px);
  margin-right: rem(8px);
}

.header-right-option-clear{
  color: $color-text-primary;
  cursor: pointer;
  line-height: rem(32px);
  // height: rem(32px);
  // width: rem(112px);
  display: flex;
  justify-content: center;
  padding: 0 rem(6px);
  font-weight: $font-weight-2;
  &:hover {
      background-color: rgba($color-background-primary-rgb, $opacity-3);
      border-radius: rem(4px);
  }
}
}

.ap-modal.ap-pattern-confirmation-close {
  width: rem(520px);
  .ap-modal-footer-customize{
    justify-content: flex-end;
  }
}

  .accessOptionPart-child-content {
    margin-left: rem(24px);
    margin-right: rem(12px);
    .ap-accordion {
      background-color: $color-background-default !important;
      // padding: rem(4px);
      border-radius: rem(8px);
      .ap-accordion-title-extra {
        padding-left: rem(12px);
        // padding-right: rem(12px);
      }
      .icon-expand-chevron-outline{
        cursor: pointer;
      }
      .ap-accordion-toggle {
        height: rem(40px);
        .ap-accordion-title {
          margin-right: rem(12px) !important;
        }
      }
    }

    .ap-accordion:nth-last-child(-n + 2) {
      margin-top: $spacing-4;
    }

    .content-clicked {
      background-color: $color-background-triple-hover !important;
    }
    .ap-accordion .ap-accordion-toggle .ap-accordion-title {
      height: rem(48px) !important;
      align-items: center;
      justify-content: space-between;
      margin-right: 0;
    }

    margin-top: $spacing-3;
    &-item {
      cursor: pointer;

      &.itemClicked {
        background-color: $color-background-triple-hover;
      }

      border-radius: $spacing-2;
      padding: $spacing-6;
      background-color: $color-background-default;
      display: flex;

      flex-direction: column;

      &-icon {
        margin-right: $spacing-3;
      }
      &-texts {
        &-title {
          letter-spacing: rem(-0.4px);
          font: $typography-heading-s;
          font-weight: $font-weight-2;
          color: $color-text-heading;
        }
        &-desc {
          font: $typography-body;
          color: $color-text-body;
          letter-spacing: rem(-0.4px);
        }
      }

      &-expandPart {
        width: 100%;

        display: none;
        &.show {
          display: block;
        }
        margin-top: $spacing-6;

        &-title {
          padding: rem(16px);

          span {
            font: $typography-body;
            font-weight: $font-weight-2;
            letter-spacing: rem(-0.4px);
            color: $color-text-heading;
          }

          span::after {
            content: '';
            background-color: #c52b19;
            display: inline-block;
            width: $spacing-2;
            height: $spacing-2;
            border-radius: $spacing-1;
            position: relative;
            bottom: $spacing-3;
            left: $spacing-1;
          }
          span.red-dot.no-top::after {
            top: unset;
          }
        }

        &-idamOrIam {
          &-terms {
            margin-top: $spacing-3;
            margin-left: $spacing-3;
            display: flex;
            flex-direction: column;

            &-single {
              ul {
                list-style-type: disc !important;
              }

              margin-top: $spacing-2;

              span:nth-child(1) {
                margin-right: $spacing-3;
              }

              span:nth-child(1),
              span:nth-child(2),
              a {
                font: $typography-body;
                font-weight: $font-weight-1;
                color: $color-text-body;
                letter-spacing: rem(-0.4px);
              }

              a {
                text-decoration: underline;
                color: $color-text-link-primary;
              }

              li {
                display: flex;
                margin-top: $spacing-2;
                span {
                  margin-left: $spacing-3;
                }
              }
            }
          }

          &-checkbox {
            margin-top: $spacing-5;
            margin-bottom: $spacing-1;

            label {
              font: $typography-body;
              font-weight: $font-weight-2;
              letter-spacing: rem(-0.4px);
              color: $color-text-body;
            }
          }
        }

        &-iamOrOther {
          margin-top: $spacing-3;
          margin-bottom: $spacing-2;

          &-desc {
            span {
              font: $typography-body;
              font-weight: $font-weight-1;
              letter-spacing: rem(-0.4px);
              color: $color-text-body;
            }
          }

          &-usrInfo {
            margin-top: $spacing-3;
            margin-right: $spacing-2;

            display: flex;
            justify-content: space-between;

            .ap-field {
              width: 50%;
            }
            .ap-field:first-child {
              padding-right: $spacing-4;
            }
            .ap-field:last-child {
              padding-left: $spacing-4;
            }
          }
        }

        &-unsure {
          display: flex;
          flex-direction: column;
          div {
            margin-top: $spacing-3;

            span {
              font: $typography-body;
              font-weight: $font-weight-1;
              letter-spacing: rem(-0.4px);
              color: $color-text-body;
            }

            span:last-child {
              margin-left: $spacing-3;
            }
          }
        }
      }
    }
    &-item:nth-last-child(-n + 2) {
      margin-top: $spacing-4;
    }
  }
  
  .ap-accordion:not(:last-child) {
    margin-bottom: 0;
  }

  .ap-accordion:hover {
    background-color: transparent;
  }

  .as-accordingItem2 {
    margin-top: $spacing-6 !important;
    .react-slidedown {
      display: none !important;
    }
  }

  .ap-accordion .ap-accordion-toggle,
  .ap-accordion .ap-accordion-content {
    padding: 0px;
  }

  .manual-review-page .ap-accordion .ap-accordion-toggle .ap-accordion-title {
    // height: rem(64px);
    align-items: center;
  }
  .manual-review-page {
    .home-projectContent-noProducts {
      margin-top: rem(12px);
    }
    .manual-review-page-title {
      font-weight: $font-weight-2;
      color: $color-text-heading;
      letter-spacing: rem(-0.4px);
      font-size: rem(24px);
      height: rem(32px);
      line-height: rem(32px);
    }
    .manual-review-page-des {
      font-weight: $font-weight-1;
      color: $color-text-body;
      letter-spacing: rem(-0.4px);
      font-size: rem(16px);
      line-height: rem(24px);
    }
  }

  .ap-accordion .ap-accordion-toggle .ap-accordion-title {
    margin-right: 0;
  }

  .manualPage-title{
    font-weight: $font-weight-2;
    color: $color-text-heading;
    letter-spacing: rem(-0.4px);
    font-size: rem(36px);
    height: rem(40px);
    line-height: rem(42px);
    margin-top: rem(32px)!important;
    margin-bottom: rem(20px);
  }

  .editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-expandPart-title{
    p {
      padding-left:rem(46px);
      padding-bottom:rem(8px);
      padding-right: rem(20px);
    }
  }
.manualPage-bottom {
  margin-bottom: $spacing-4;
  flex: 0 0 auto;
  .manualPage-bottom-content {
    border-top: rem(1px) solid $color-background-border;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: $spacing-5 0;
    // margin-top: rem(12px);
    width: 100%;
    .ap-button{
      width: rem(164px);
    }
  }
}
.ap-container.manualPage.loading {
  .manualPage-bottom {
    // &.fixed {
      display: none;
    // }
  }
  .manualPage-bottom {
    transform: translateY(rem(12px));
  }
}
.manualPage-title.loading{
  opacity: 0;
}

.ap-modal.ap-pattern-confirmation-close, .ap-modal.ap-pattern-confirmation-delete {
  width: rem(540px);
  .ap-pattern-confirmation-message {
    line-height: rem(24px);
    height: rem(48px);
  }
  .ap-modal-body {
    margin-top:rem(4px);
    padding-bottom: 0;
  }
  .ap-modal-footer {
    margin-top: rem(20px);
  }
}

.impacted-url-table {
  position: relative;
  margin-top: $spacing-3;

  width: 100%;
  padding: var(--spacing-2) var(--spacing-3);
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
  border-radius: var(--border-radius-3);

  .ap-table {
    white-space: nowrap;
    overflow-x: auto;

    box-shadow: unset;
    border-radius: unset;
    background-color: unset;

    padding-bottom: 5px;
    padding-right: 0;
    padding-left: 0;
    table {
      a {
        font: $typography-body;
        font-weight: $font-weight-1;
        letter-spacing: rem(-0.4px);
        text-decoration: underline;
        color: $color-text-link-primary;
      }

      border-collapse: separate;
      border-spacing: unset !important;

      tbody tr {
        height: rem(48px) !important;
      }

      tbody tr:hover {
        background-color: var(--color-background-hover) !important;
      }

      // tbody tr:nth-child(odd) {
      //   td:nth-last-child(-n + 1) {
      //     background-color: $color-background-selected;
      //   }
      // }

      // tbody tr:nth-child(even) {
      //   td:nth-last-child(-n + 1) {
      //     background-color: $color-background-container;
      //   }
      // }

      thead tr th:nth-child(1) {
        width: rem(180px);
      }

      thead tr th:nth-child(-n + 1) {
        background-color: $color-background-container;
      }

      tbody td {
        padding-top: $spacing-3 !important;
        padding-bottom: $spacing-3 !important;
        &:nth-child(1) {
          width: rem(688px);
        }
        .ap-field-wrapper {
          height: rem(32px) !important;
        }

        input {
          text-align: left;
          margin: 0px 0px 0px $spacing-3 !important;
        }
      }

      thead tr th:nth-child(3),
      tbody tr td:nth-child(3) {
        position: sticky;
        right: 0;
        z-index: 10;
        border-left: 1px solid $color-background-border;
        width: rem(94px);
      }
    }
  }

  &-line {
    position: absolute;
    z-index: 99;
    width: 1px;
    background-color: $color-background-border;
    right: rem(60px);
    top: rem(12px);
    bottom: rem(10px);
  }

  td {
    vertical-align: middle;
  }
}

@media screen and (min-width: 768px) and (max-width: 1439px) {
  .ap-container.manualPage {
    max-width: 100%;
  }
  .ap-panel-with-extra-container.ap-container{
    max-width: 100%;
  }
  .manual-review-nav{
    height: calc(100% - 52px);
  }
  .manualPage .ap-panel-with-extra-container {
    height: calc(100% + 4px);
  }
  .manualPage .manualPage-content-section .manualPage-content.smallTemp .col-4 {
    padding-right: rem(8px);
  }
  .manualPage-content-section{
    .manualPage-content {
      &.smallTemp {
        .col-4{
          @media screen and (min-width: 768px) and (max-width: 1439px) {
            width:36%!important;
            @media screen and (min-width: 977px) and (max-width: 1439px) {
              max-width: rem(348px)!important;
            }
            &.fixed{
              width: calc(36% - rem(8.64px))!important;
              @media screen and (min-width: 1240px) and (max-width: 1439px) {
                width: calc(36% - rem(8.64px) - rem(5.75px))!important;
              }
            }
          }
          padding-right: 0!important;
        }
        .col-8.manual9{
          margin-left: rem(8px)!important;
          @media screen and (min-width: 768px) and (max-width: 1439px) {
            &.fixed{
              margin-left: calc(36% + rem(8px))!important;
              @media screen and (min-width: 977px) and (max-width: 1439px) {
                margin-left: calc(rem(348px) + rem(8px))!important;
              }
            }
          }
          @media screen and (min-width: 768px) and (max-width: 1439px) {
            width: calc(100% - 36% - rem(20px))!important;
          }
          @media screen and (min-width: 977px) and (max-width: 1439px) {
            width: calc(100% - rem(348px) - rem(20px))!important;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1441px) {
  .ap-container.manualPage .manualPage-content-section {
    margin-bottom: rem(12px);
  }
}
@media screen and (min-width: 768px) and (max-width: 1440px) {
  .ap-container.manualPage .manualPage-content-section {
    margin-bottom: rem(12px);
  }
}
@media screen and (min-width: 1440px) {
  .ap-container.manualPage {
    .manualPage-content-section{
      max-width: rem(1360px);
      // margin-bottom: rem(12px);
    }
    margin-left: auto;
    margin-right: auto;
  }
}

// @media screen and (max-width: 768px) {
//   .ap-container.manualPage .manualPage-content-section {
//     margin-bottom: rem(24px);
//   }
// }
[data-mode='dark'] {
  .ap-container.manualPage{
    .ap-accordion-title-extra.ap-accordion-title .custom-issue-title-section .ap-badge span{
      color: unset;
    }
  }
  .manual-suggestions-lightPart {
    background-color: $color-background-hover-selected;
  }
}
@media screen and (max-width: 833px) {
  .data-list-filter.has-tag {
    .tags-panel {
      .ap-tag:not(:last-child) {
        margin-right: rem(1.5px);
      }
    }
  }
}
