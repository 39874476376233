@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';

.ap-page-release-notes {
  margin-top: rem(16px);
  margin-bottom: rem(32px);
  .ap-page-section-desc {
    font-size: $typography-text-size-3;
    line-height: $typography-line-height-3;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: $letter-spacing-1;
    color: $color-text-body;
    word-wrap: break-word;
  }

  .tick {
    width: rem(4px);
    height: 100%;
    background-color: $color-background-primary;
  }

}
.ap-component-wrapper.release-notes{
  margin-top: rem(32px);
  margin-bottom: rem(16px);
  .ap-component-name.anchor-target {
    margin-bottom: rem(16px);
  }
}
.ap-release-notes-container {
  margin-top: rem(16px);
  padding-left: rem(108px);
  position: relative;

  .ap-release-notes-timeline {
    cursor: default;
    position: absolute;
    width: rem(108px);
    height: 100%;
    left: rem(-108px);
    top: 0;

    &-date {
      white-space: nowrap;
      margin-top: $spacing-32;
      transform: translateY(-50%);
      color: $color-text-light;
      font-size: $typography-text-size-1;
      letter-spacing: rem(-0.2px);
      line-height: $typography-line-height-1;
      width: rem(72px);
      position: absolute;

      &.date-active {
        color: $color-text-primary;
        font-weight: $font-weight-2;
      }
    }

    &-tick {
      position: absolute;
      height: $height-0;
      width: $width-0;
      border-radius: 50%;
      background-color: $color-background-triple-hover;
      left: rem(90px);
      top: $spacing-32;
      transform: translate(-50%, -50%);
      z-index: $z-index-1;

      &.tick-active {
        height: $height-20;
        width: $width-20;
        background-color: $color-background-primary;
      }
    }

    &-rail {
      position: absolute;
      height: calc(100% + $height-20);
      width: rem(4px);
      border-radius: $border-radius-1;
      background-color: $color-background-triple-hover;
      left: rem(88px);
      top: rem(28px);
      // z-index: $z-index-0;
    }
  }

  .ap-release-notes-item-content-detail {
    margin-top: rem(10px);
    .detail-version-wrapper {
      height: $height-28;
      width: 100%;
      border-radius: $border-radius-2;
      background-color: $color-background-default;
      padding: $spacing-2 $spacing-3;
      margin-bottom: $spacing-6;

      .detail-version {
        color: $color-text-body;
        font-size: $typography-text-size-2;
        letter-spacing: $letter-spacing-1;
        line-height: $typography-line-height-2;
      }

      &:not(:first-child) {
        margin-top: rem(40px);
      }
    }

    .detail-content {
      &-title {
        color: $color-text-body;
        font-size: $typography-text-size-4;
        font-weight: $font-weight-2;
        letter-spacing: $letter-spacing-1;
        line-height: $typography-line-height-3;
        margin-bottom: $spacing-3;
      }

      &-desc {
        color: $color-text-body;
        font-size: $typography-text-size-3;
        letter-spacing: $letter-spacing-1;
        line-height: $typography-line-height-3;
        list-style: disc;

        &-sub-title {
          color: $color-text-heading;
          font-weight: $font-weight-2;
        }

        &-sub-desc {
          margin-top: $spacing-3;
        }

        li {
          list-style: disc;
          margin-left: $spacing-6;
        }

        li+li {
          margin-top: rem(8px);
        }
      }

      &-text {
        color: $color-text-body;
        font-size: $typography-text-size-3;
        letter-spacing: $letter-spacing-1;
        line-height: $typography-line-height-3;

        &-title {
          color: $color-text-heading;
          font-weight: $font-weight-2;
        }

        &:not(:last-child) {
          margin-bottom: $spacing-4;
        }
      }
    }

    .detail-content+.detail-content {
      margin-top: rem(8px);
    }
  }
}

.ap-release-notes-container {

  // Update style for accordion
  .ap-release-notes-accordion {
    .ap-accordion {
      background-color: $color-background-container !important;
      border-radius: $border-radius-3 !important;
      box-shadow: 0 2px 4px -2px rgba(70, 70, 70, 0.24);
      padding: $spacing-6;

      .ap-accordion-toggle {
        padding: 0;
        .ap-accordion-title {
          align-items: flex-end;
          .ap-badge.ap-badge-primary.ap-badge-small{
            margin-right: rem(8px);
          }
        }
      }

      .ap-accordion-content {
        padding-right: $spacing-4 !important;
        padding-left: 0;
      }

      &:last-child {
        .ap-release-notes-timeline-rail {
          display: none;
        }
      }

      &:not(:last-child) {
        margin-bottom: $spacing-4;
      }
    }
  }

  .ap-release-notes-item-content-badge {
    margin-right: $spacing-3;
  }

  .ap-release-notes-item-content-title {
    font-size: $typography-text-size-4;
    color: $color-text-body;
    // margin-left: $spacing-3;
    @media screen and (min-width: 768px) and (max-width: 1080px) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: rem(360px);
      &.latest{
        max-width: rem(320px);
      }
    }
  }

  .text-bold {
    font-weight: $font-weight-2;
  }
}

@media screen and (min-width: 1440px) {
  .ap-page.ap-page-release-notes.has-anchor {
    width: calc(100% - rem(262px));
  }

  @media screen and (min-width: 1441px) {
    .ap-container:has(.ap-page.ap-page-release-notes.has-anchor) {
      width: rem(1160px);
    }
  }
}

.ap-page.ap-page-release-notes+.not-first-use-tool-footer.help-center .footer-container {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .ap-container.home:not(.getting-started):has(.ap-page-release-notes) {
    padding-left: var(--ap-gutter-x, 0.5rem)!important;
    max-width: calc(100vw - rem(280px));
  }
}