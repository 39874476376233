@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';
.conformancebar-container {
  .score-area {
    & span {
      color: $color-text-heading;
      font-feature-settings:
        'clig' off,
        'liga' off;

      font-size: $typography-text-size-7;
      font-style: normal;
      font-weight: $font-weight-2;
      line-height: $typography-data;
      letter-spacing: rem(-0.8px);
    }

    .score-desc {
      margin-top: $spacing-1;
      color: $color-text-body;
      font-feature-settings:
        'clig' off,
        'liga' off;

      font-size: $typography-text-size-3;
      font-style: normal;
      font-weight: $font-weight-1;
      line-height: rem(24px);
      letter-spacing: rem(-0.4px);
    }
  }

  .bar-area {
    .loading-area {
      position: relative;
      height: rem(29px);
      display: flex;
      align-items: center;
      margin-top: $spacing-2;

      .loading-bar-outer {
        border-radius: $border-radius-2;
        width: 100%;

        .loading-bar-inner {
          border-radius: $border-radius-2;
        }
      }

      & img {
        position: absolute;
      }
    }

    .percent-number-area {
      display: flex;
      align-items: center;
      position: relative;
      height: rem(32px);
      color: $color-text-body;

      & span {
        position: absolute;
      }

      & span:last-child {
        left: calc(100% - rem(40px));
      }
    }
  }

  .lengend-area {
    display: flex;
    margin-top: $spacing-3;
    // flex-wrap: wrap;
    row-gap: $spacing-3;

    & span {
      color: $color-text-body;
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-size: rem(12px);
      font-style: normal;
      font-weight: $font-weight-1;
      line-height: rem(14px);
    }

    .your-conformance,
    .minimum-required {
      display: flex;
    }

    .your-conformance,
    .minimum-required,
    .end-goal {
      display: flex;
      align-items: center;
    }

    .minimum-required,
    .end-goal {
      margin-left: $spacing-5;
    }

    .your-conformance {
      margin-right: 0px;
      & div {
        width: rem(8px);
        height: rem(8px);
        margin-right: 4px;
        font-feature-settings:
          'clig' off,
          'liga' off;

        font-size: rem(12px);
        font-style: normal;
        font-weight: $font-weight-1;
        line-height: rem(14px);
      }
    }

    .minimum-required {
      & img {
        margin-right: $spacing-2;
      }
    }

    .end-goal {
      & div {
        width: rem(8px);
        height: rem(8px);
        margin-right: $spacing-2;
      }
    }

    @media screen and (max-width: 800px) {
      flex-direction: column !important;
      .minimum-required,
      .end-goal {
        margin-left: 0px !important;
      }
    }
  }
}
