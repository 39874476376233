@import 'node_modules/sass-rem/_rem.scss';
@import '../../../styles/scss/variables';

.editPagesToScanModal {
  &-enterPart {
    // height: $spacing-8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: $spacing-6;

    .ap-field-content {
      margin-top: $spacing-3;
      font: $typography-body-xs;
      line-height: rem(12px) !important;
      font-weight: $font-weight-1;
      color: $color-text-error;
    }

    .ap-field {
      width: 80%;
      padding-right: $spacing-3;
    }
    button {
      width: 20%;
    }
  }

  &-tablePart {
    margin-top: $spacing-6;
    a {
      &:hover {
          text-decoration: none!important;
      }
    }
    .ap-field.has-length .ap-field-title-container, .ap-field:not(.disabled):not(.readonly):not(.text-readonly):focus-within .ap-field-title-container{
      display: none;
    }
    .ap-field .ap-field-container .ap-field-wrapper input {
      padding: 0;
    }
    .ap-field .ap-field-title-container {
      top: $spacing-1;
    }
    .ap-field .ap-field-title-container {
      top: var(--spacing-2);
    }
    .ap-field.has-length .ap-field-title-container, .ap-field:not(.disabled):not(.readonly):not(.text-readonly):focus-within .ap-field-title-container, .ap-field:not(.readonly) .ap-field-container.open .ap-field-title-container, .ap-field:not(.readonly) .ap-field-container.show .ap-field-title-container {
        display: none;
    }
    .ap-field.has-length .ap-field-title-container, .ap-field:not(.disabled):not(.readonly):not(.text-readonly):focus-within .ap-field-title-container{
      display: none;
    }
    .ap-field .ap-field-container .ap-field-wrapper input {
      padding: 0;
    }
    &-showingPart {
      margin-bottom: $spacing-2;
    }

    &-table {
      position: relative;
      margin-top: $spacing-3;

      width: 100%;
      padding: var(--spacing-2) var(--spacing-3);
      box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
      border-radius: var(--border-radius-3);

      .ap-table {
        white-space: nowrap;
        overflow-x: auto;

        box-shadow: unset;
        border-radius: unset;
        background-color: unset;

        padding-bottom: 5px;
        padding-right: 0;

        &.ap-table-condensed tbody tr td {
          padding: $spacing-4 !important;
        }

        table {
          a {
            font: $typography-body;
            font-weight: $font-weight-1;
            letter-spacing: rem(-0.4px);
            text-decoration: underline;
            color: $color-text-link-primary;
          }

          border-collapse: separate;
          border-spacing: unset !important;

          tbody tr:nth-child(odd) {
            td:nth-last-child(-n + 2) {
              background-color: $color-background-selected;
            }
          }
          tbody tr:nth-child(even) {
            td:nth-last-child(-n + 2) {
              background-color: $color-background-container;
            }
          }
          thead tr th:nth-child(1) {
            width: rem(180px);
          }

          tbody td {
            .ap-field-wrapper {
              height: rem(32px) !important;
            }
            input {
              text-align: left;
              // margin: 0 !important;
              margin: 0px 0px 0px $spacing-3 !important;
            }
            span {
              cursor: pointer;
            }
          }

          thead tr th:nth-child(3),
          tbody tr td:nth-child(3) {
            position: sticky;
            right: 0;
            border-left: 1px solid $color-background-border;
            width: rem(94px);
          }
        }
      }

      &-line {
        position: absolute;
        z-index: 99;
        width: 1px;
        background-color: $color-background-border;
        right: rem(260px);
        top: rem(12px);
        bottom: rem(10px);
      }

      &-pendingColor {
        color: $color-background-warning;
      }

      td {
        vertical-align: middle;
      }
    }

    &-pagnation {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
