@mixin threeDotsButtonCustomNode {
  width: rem(40px);
  height: rem(40px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-2;
  cursor: pointer;
  position: absolute;
  right: 0;
  &:hover{
    background-color: $color-background-hover;
  }
}