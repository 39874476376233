@import 'node_modules/sass-rem/_rem.scss';
@import '../../../styles/scss/variables';

.selectUser {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-2;
  padding-right: $spacing-4;
  height: rem(64px);

  &-avatar {
    width: 10%;
  }
  &-userInfo {
    width: 45%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-right: $spacing-3;

    span:first-child {
      font: $typography-body;
      letter-spacing: rem(-0.4px);
      color: $color-text-body !important;
      font-weight: $font-weight-2;
    }

    span:last-child {
      font: $typography-body;
      letter-spacing: rem(-0.4px);
      font-weight: $font-weight-1 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &-selectRole {
    // width: 40%;
    width: rem(178px);
    margin-right: $spacing-5;
  }
  &-deleteIcon {
    width: 5%;
    cursor: pointer;
  }

  &-owner-empty {
    width: 35%;
  }

  &-owner-text {
    width: 10%;
    &-span {
      font: $typography-body;
      font-weight: $font-weight-1;
      color: $color-text-light;
      letter-spacing: rem(-0.4px);
    }
  }
}
