@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';

.settingsPage {
  &-settingsLabel {
    font-weight: $font-weight-2;
    font-size: rem(36px);
    line-height: rem(42px);
    letter-spacing: rem(-0.4px);
    margin-top: $spacing-5 !important;
  }
  a {
    &:hover {
        text-decoration: none!important;
    }
  }
  &-tabsContainer {
    width: 100%;
    margin-top: rem(32px) !important;

    .ap-tabset-container {
      .ap-tabset-toggle-wrapper {
        .ap-tabset-underline {
          background-color: $color-background-hover-selected !important;
        }
      }
    }
  }

  &-teamers {
    margin-top: $spacing-7 !important;
    padding: $spacing-6;
    border-radius: $spacing-3;
    background-color: $color-background-container;
    box-shadow: $box-shadow-1;

    &-headerBtn {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        font: $typography-heading-m;
        font-weight: $font-weight-2;
        letter-spacing: rem(-0.4px);
        color: $color-text-heading;
      }
    }

    &-tableArea {
      margin-top: $spacing-6;

      &-topText {
        font: $typography-body-s;
        font-weight: $font-weight-1;
        letter-spacing: rem(-0.2px);
        color: $color-text-light;
      }

      &-table {
        margin-top: $spacing-2;

        .table-overflow {
          overflow-x: auto !important;
        }
      }
    }
  }

  &-autoScan {
    margin-top: $spacing-7 !important;
    padding: $spacing-6;
    border-radius: $spacing-3;
    background-color: $color-background-container;
    box-shadow: $box-shadow-1;

    &-titleModifyBtn {
      &-titlePart {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
          font: $typography-heading-m;
          font-weight: $font-weight-2;
          letter-spacing: rem(-0.4px);
          color: $color-text-heading;
        }
      }

      &-issuePart {
        display: flex;
        align-items: baseline;
        margin-top: $spacing-6;
        padding: $spacing-3 $spacing-4;
        background-color: $color-background-container-alt;
        border-radius: $spacing-3;

        div {
          display: flex;
          flex-direction: column;
          margin-left: $spacing-3;
          font: $typography-body;
          font-weight: $font-weight-1;
          color: $color-text-body;

          a {
            color: $color-text-link-primary;
            text-decoration: underline;
          }
        }
      }
    }

    &-urlTablePart {
      margin-top: $spacing-6;

      &-topText {
        display: flex;
        justify-content: space-between;
        font: $typography-body-s;
        font-weight: $font-weight-1;
        letter-spacing: rem(-0.2px);
        color: $color-text-light;

        &-unreachablePart {
          display: flex;
          align-items: center;

          div:first-child {
            display: flex;
            align-items: center;
            font-size: $spacing-3;
          }

          div:last-child {
            display: flex;
            align-items: center;
            margin-left: $spacing-2;
          }
        }

        &-erorColor {
          color: $color-background-danger;
          width: 100% !important;
          height: 100% !important;
        }
      }

      &-table {
        position: relative;
        margin-top: $spacing-3;

        width: 100%;
        padding: var(--spacing-2) var(--spacing-3);
        box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
        border-radius: var(--border-radius-3);

        .ap-table {
          white-space: nowrap;
          overflow-x: auto;

          box-shadow: unset;
          border-radius: unset;
          background-color: unset;

          padding-bottom: 5px;
          padding-right: 0;

          table {
            a {
              font: $typography-body;
              font-weight: $font-weight-1;
              letter-spacing: rem(-0.4px);
              text-decoration: underline;
              color: $color-text-link-primary;
            }

            border-collapse: separate;
            border-spacing: unset !important;

            tbody tr {
              height: rem(48px) !important;
            }

            tbody tr:hover {
              background-color: var(--color-background-hover) !important;
            }

            tbody tr:nth-child(odd) {
              td:nth-last-child(-n + 1) {
                background-color: $color-background-selected;
              }
            }

            tbody tr:nth-child(even) {
              td:nth-last-child(-n + 1) {
                background-color: $color-background-container;
              }
            }

            thead tr th:nth-child(1) {
              width: rem(180px);
            }

            thead tr th:nth-child(-n + 1) {
              background-color: $color-background-container;
            }

            tbody td {
              padding-top: $spacing-3 !important;
              padding-bottom: $spacing-3 !important;

              .ap-field-wrapper {
                height: rem(32px) !important;
              }

              input {
                text-align: left;
                // margin: 0 !important;
                margin: 0px 0px 0px $spacing-3 !important;
              }
            }

            // thead tr th:nth-child(4),
            // tbody tr td:nth-child(4) {
            //   position: sticky;
            //   right: 0;
            //   z-index: 10;
            //   width: rem(132px);
            // }

            thead tr th:nth-child(3),
            tbody tr td:nth-child(3) {
              position: sticky;
              right: 0;
              z-index: 10;
              border-left: 1px solid $color-background-border;
              width: rem(94px);
            }
          }
        }

        &-line {
          position: absolute;
          z-index: 99;
          width: 1px;
          background-color: $color-background-border;
          right: rem(260px);
          top: rem(12px);
          bottom: rem(10px);
        }

        td {
          vertical-align: middle;
        }
      }

      &-pageNationPart {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: $spacing-2;

        &-showPart {
          display: flex;
          align-items: center;

          span {
            font-weight: $font-weight-1;
            font-size: rem(16px);
            line-height: rem(24px);
            letter-spacing: rem(-0.4px);
          }

          &-pageDropdownPart {
            margin-left: $spacing-3;
            margin-right: $spacing-3;
            width: rem(80px);

            .ap-field-container {
              width: rem(76px);

              .ap-field-title-container {
                top: $spacing-2 !important;
              }

              .ap-field-wrapper {
                line-height: $spacing-32;
                height: $height-10 !important;
                border-radius: $spacing-2 !important;
              }

              &.hide-title input {
                margin: 0 0 0 $spacing-3 !important;
              }
            }

            .icon-down-chevron-outline {
              margin-top: $spacing-2 !important;
            }
          }

          &-pagePart {
          }
        }
      }
    }
  }

  &-basicInfo {
    margin-top: $spacing-7 !important;
    padding: $spacing-6;
    background-color: $color-background-container;
    border-radius: $spacing-3;
    box-shadow: $box-shadow-1;

    &-titlePart {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        font: $typography-heading-m;
        font-weight: $font-weight-2;
        letter-spacing: rem(-0.4px);
        color: $color-text-heading;
      }

      &-editIcon {
        cursor: pointer;
      }
    }

    &-productName {
      margin-top: $spacing-6;

      h3 {
        font: $typography-body-s;
        font-weight: $font-weight-1;
        letter-spacing: rem(-0.2px);
        color: $color-text-body;
        height: rem(20px);
      }

      span {
        font: $typography-body;
        font-weight: $font-weight-1;
        letter-spacing: rem(-0.4px);
        color: $color-text-body;
        height: rem(24px);
      }
    }

    &-privatePart {
      margin-top: $spacing-6;

      h3 {
        font: $typography-body-s;
        font-weight: $font-weight-1;
        letter-spacing: rem(-0.2px);
        color: $color-text-body;
        height: rem(20px);
      }

      &-flag {
        font: $typography-body;
        font-weight: $font-weight-1;
        letter-spacing: rem(-0.4px);
        color: $color-text-body;
        height: rem(24px);
      }

      &-issue {
        display: flex;
        align-items: flex-start;
        background-color: $color-background-default;
        margin-top: $spacing-3;
        color: $color-text-body;
        font-feature-settings:
          'clig' off,
          'liga' off;

        font-size: rem(16px);
        font-style: normal;
        font-weight: $font-weight-1;
        line-height: rem(24px);
        letter-spacing: rem(-0.4px);
        padding: $spacing-4 $spacing-5;
        border-radius: $border-radius-3;

        .recomDescContainer {
          display: flex;
          align-items: flex-start;

          .recoDescIcon {
            margin-right: $spacing-3;
          }
        }
      }
    }

    &-dataRetenion {
      margin-top: $spacing-6;

      h3 {
        font: $typography-body-s;
        font-weight: $font-weight-1;
        letter-spacing: rem(-0.2px);
        color: $color-text-body;
        height: rem(20px);
      }

      &-flag {
        font: $typography-body;
        font-weight: $font-weight-1;
        letter-spacing: rem(-0.4px);
        color: $color-text-body;
        display: inline-block;
        height: rem(24px);
      }

      &-issue {
        display: flex;
        align-items: flex-start;
        background-color: $color-background-default;
        margin-top: $spacing-3;
        color: $color-text-body;
        font-feature-settings:
          'clig' off,
          'liga' off;

        font-size: rem(16px);
        font-style: normal;
        font-weight: $font-weight-1;
        line-height: rem(24px);
        letter-spacing: rem(-0.4px);
        padding: $spacing-4 $spacing-5;
        border-radius: $border-radius-3;

        .recomDescContainer {
          display: flex;
          align-items: flex-start;

          .recoDescIcon {
            margin-right: $spacing-3;
          }
        }
      }
    }
  }

  &-noMemberPage {
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $color-background-container;
    border-radius: $border-radius-3;
    padding: $spacing-5 $spacing-3;
    box-shadow: $box-shadow-1;
    margin-top: $spacing-7;

    .imgPart {
      img {
        width: 100%;
        height: 100%;
      }
    }

    .boldTitlePart {
      font-weight: $font-weight-2;
      font-size: rem(20px);
      line-height: rem(24px);
      letter-spacing: rem(-0.4px);
      color: $color-text-heading;
      padding-top: $spacing-5;
      text-align: center;
    }

    .descPart {
      padding-top: $spacing-3;
      font-weight: $font-weight-1;
      font-size: rem(16px);
      max-width: rem(547px);
      line-height: rem(24px);
      letter-spacing: rem(-0.4px);
      color: $color-text-body;
      text-align: center;
      text-wrap: wrap;
    }

    .runAutoScanPart {
      padding-top: $spacing-5;
    }

    @media screen and (max-width: 1048px) {
    }
  }

  &-modalFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    &-descArea {
      margin-left: $spacing-3 !important;
      span {
        position: relative;
        font-size: rem(14px);
        font-weight: $font-weight-1;
        line-height: rem(16px);
        letter-spacing: rem(-0.4px);
        color: $color-text-light;
      }

      span::before {
        content: '';
        background-color: #c52b19;
        position: absolute;
        left: rem(-8px);
        top: $spacing-3;
        display: inline-block;
        width: $spacing-2;
        height: $spacing-2;
        border-radius: $spacing-1;
      }
    }

    &-btnArea {
      display: flex;

      button:nth-child(1) {
        margin-right: $spacing-3;
      }
    }
  }

  &-modalBody {
    &-addTeamerPart {
      //margin-top: $spacing-6;
      &-label {
        font: $typography-body;
        letter-spacing: rem(-0.4px);
        font-weight: $font-weight-2;
      }

      &-search {
        &-container {
          height: $spacing-8;

          .ap-search-container {
            // padding: 0 !important;
            z-index: 9;

            &.show {
              padding-left: $spacing-3;
              padding-right: $spacing-3;
            }

            input::-webkit-input-placeholder {
              font-size: rem(16px);
              font-weight: $font-weight-1;
              line-height: rem(20px);
              letter-spacing: rem(-0.4px);
              color: $color-text-light;
            }

            input::-moz-placeholder {
              /* Firefox 19+ */
              font-size: rem(16px);
              font-weight: $font-weight-1;
              line-height: rem(20px);
              letter-spacing: rem(-0.4px);
              color: $color-text-light;
            }

            input:-ms-input-placeholder {
              /* IE 10+ */
              font-size: rem(16px);
              font-weight: $font-weight-1;
              line-height: rem(20px);
              letter-spacing: rem(-0.4px);
              color: $color-text-light;
            }

            input:-moz-placeholder {
              /* Firefox 18- */
              font-size: rem(16px);
              font-weight: $font-weight-1;
              line-height: rem(20px);
              letter-spacing: rem(-0.4px);
              color: $color-text-light;
            }
          }
        }

        &-divider {
          opacity: 0.32;
          background-color: $color-text-heading;
          width: 100%;
          height: rem(1px);
          margin-top: $spacing-5;
        }

        &-contentContainer {
          max-height: rem(320px);
          overflow-y: auto;
          margin-top: $spacing-5;
        }
      }
    }

    &-issue {
      display: flex;
      align-items: flex-start;
      background-color: $color-background-default;
      margin-top: $spacing-3;
      color: $color-text-body;
      font-feature-settings:
        'clig' off,
        'liga' off;

      font-size: rem(16px);
      font-style: normal;
      font-weight: $font-weight-1;
      line-height: rem(24px);
      letter-spacing: rem(-0.4px);
      padding: $spacing-4 $spacing-5;
      border-radius: $border-radius-3;

      .recomDescContainer {
        display: flex;
        align-items: flex-start;

        .recoDescIcon {
          margin-right: $spacing-3;
        }
      }
    }
  }

  &-basicInfoModal {
    &-modalBody {
      &-productName {
        input::-webkit-input-placeholder {
          font-size: rem(16px);
          font-weight: $font-weight-1;
          line-height: rem(20px);
          letter-spacing: rem(-0.4px);
          color: $color-text-light;
        }

        input::-moz-placeholder {
          /* Firefox 19+ */
          font-size: rem(16px);
          font-weight: $font-weight-1;
          line-height: rem(20px);
          letter-spacing: rem(-0.4px);
          color: $color-text-light;
        }

        input:-ms-input-placeholder {
          /* IE 10+ */
          font-size: rem(16px);
          font-weight: $font-weight-1;
          line-height: rem(20px);
          letter-spacing: rem(-0.4px);
          color: $color-text-light;
        }

        input:-moz-placeholder {
          /* Firefox 18- */
          font-size: rem(16px);
          font-weight: $font-weight-1;
          line-height: rem(20px);
          letter-spacing: rem(-0.4px);
          color: $color-text-light;
        }

        &-inputError {
          font-size: rem(12px);
          font-weight: $font-weight-1;
          line-height: rem(12px);
          letter-spacing: rem(-0.2px);
          color: $color-text-error;
          margin-top: $spacing-3;
          margin-left: $spacing-3;
        }
      }

      &-privacyPart {
        margin-top: $spacing-6;

        &-title {
          font: $typography-body;
          letter-spacing: rem(-0.4px);
          font-weight: $font-weight-2 !important;
        }

        &-toggle {
          margin-top: $spacing-3;

          &-switch {
            cursor: pointer;
            width: rem(150px);
          }

          .ap-switch-label {
            font-weight: 400 !important;
          }
        }

        &-desc {
          display: flex;
          align-items: center;
          margin-top: $spacing-4;
          background-color: $color-background-default;
          border-radius: $spacing-3;
          height: $spacing-8;
          padding: $spacing-3 $spacing-4;

          span:first-child {
            margin-right: $spacing-3;
          }

          span:last-child {
            font: $typography-body;
            font-weight: $font-weight-1;
            color: $color-text-body;
            letter-spacing: rem(-0.4px);
          }
        }
      }

      &-dataRetentionPart {
        margin-top: $spacing-6;

        &-title {
          font: $typography-body;
          letter-spacing: rem(-0.4px);
          font-weight: $font-weight-2 !important;
        }

        &-toggle {
          margin-top: $spacing-3;

          &-switch {
            cursor: pointer;
          }

          .ap-switch-label {
            font-weight: 400 !important;
          }
        }

        &-desc {
          display: flex;
          // align-items: center;
          margin-top: $spacing-4;
          background-color: $color-background-default;
          border-radius: $spacing-3;
          // height: $spacing-8;
          padding: $spacing-3 $spacing-4;

          span:first-child {
            margin-right: $spacing-3;
          }

          span:last-child {
            font: $typography-body;
            font-weight: $font-weight-1;
            color: $color-text-body;
            letter-spacing: rem(-0.4px);
          }
        }
      }
    }
  }

  &-basicInfoModal-modalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &-descArea {
      margin-left: $spacing-3;
      span {
        position: relative;
        font-size: rem(14px);
        font-weight: $font-weight-1;
        line-height: rem(16px);
        letter-spacing: rem(-0.4px);
        color: $color-text-light;
      }

      span::before {
        content: '';
        background-color: #c52b19;
        position: absolute;
        left: rem(-8px);
        top: $spacing-3;
        display: inline-block;
        width: $spacing-2;
        height: $spacing-2;
        border-radius: $spacing-1;
      }
    }

    &-btnArea {
      display: flex;

      button:nth-child(1) {
        margin-right: $spacing-3;
      }
    }
  }

  &-pendingColor {
    color: $color-background-warning;
  }

  &-errorColor {
    color: $color-background-danger;
  }

  &-completeColor {
    color: $color-background-success;
  }

  &-scaningColor {
    color: $color-background-primary;
  }

  &-noScan {
    margin-top: $spacing-7;
  }

  &-accessRequirements {
    margin-top: $spacing-7;
    padding: $spacing-6;
    border-radius: $spacing-3;
    background-color: $color-background-container;
    box-shadow: $box-shadow-1;
    width: 100%;

    &-no {
      height: 80%;
    }

    &-reviewPart {
      &-accessRequirements {
        &-title {
          font: $typography-body;
          letter-spacing: rem(-0.4);
          color: $color-text-heading;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: $spacing-6;

          h2 {
            font-weight: $font-weight-2;
            font: $typography-heading-m;
            letter-spacing: rem(-0.4px);
          }
        }

        &-content {
          display: flex;

          margin-top: $spacing-3;
          border-radius: $spacing-2;
          padding: $spacing-6;
          background-color: $color-background-default;

          &-right {
            margin-left: $spacing-3;
            h2 {
              font: $typography-heading-s;
              font-weight: $font-weight-2;
              letter-spacing: rem(-0.4px);
              color: $color-text-heading;
            }

            span {
              font: $typography-body;
              font-weight: $font-weight-1;
              letter-spacing: rem(-0.4px);
              color: $color-text-body;
            }
          }
        }

        &-authTypePart {
          margin-top: $spacing-6;

          &-title {
            font: $typography-body;
            font-weight: $font-weight-1;
            color: $color-text-light;
          }

          &-content {
            display: flex;

            margin-top: $spacing-3;
            border-radius: $spacing-2;
            padding: $spacing-6;
            background-color: $color-background-default;

            &-right {
              h2 {
                font: $typography-heading-s;
                font-weight: $font-weight-2;
                letter-spacing: rem(-0.4px);
                color: $color-text-heading;
              }

              span {
                font: $typography-body;
                font-weight: $font-weight-1;
                letter-spacing: rem(-0.4px);
                color: $color-text-body;
              }
            }
          }
        }

        &-adminPart {
          margin-top: $spacing-6;

          &-title {
            font: $typography-body;
            font-weight: $font-weight-1;
            color: $color-text-light;
          }

          &-content {
            margin-top: $spacing-3;

            display: flex;
            justify-content: space-between;

            .ap-field {
              width: 50%;
            }

            .ap-field:first-child {
              padding-right: $spacing-4;
            }

            .ap-field:last-child {
              padding-left: $spacing-4;
            }
          }
        }
      }
    }
  }

  &-otherSettings {
    margin-top: $spacing-7 !important;
    padding: $spacing-6;
    background-color: $color-background-container;
    border-radius: $spacing-3;
    box-shadow: $box-shadow-1;

    &-titlePart {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        font: $typography-heading-m;
        font-weight: $font-weight-2;
        letter-spacing: rem(-0.4px);
        color: $color-text-heading;
      }

      &-editIcon {
        cursor: pointer;
      }
    }

    &-productName {
      display: flex;
      flex-direction: column;
      margin-top: $spacing-6;

      h3 {
        font: $typography-body-s;
        font-weight: $font-weight-1;
        letter-spacing: rem(-0.2px);
        color: $color-text-body;
        height: rem(20px);
        display: inline-block;
      }

      span {
        font: $typography-body;
        font-weight: $font-weight-1;
        letter-spacing: rem(-0.4px);
        color: $color-text-body;
        display: inline-block;
        height: rem(24px);
      }
    }

    &-privatePart {
      // margin-top: $spacing-6;
      h3 {
        font: $typography-body-s;
        font-weight: $font-weight-1;
        letter-spacing: rem(-0.2px);
        color: $color-text-body;
        height: rem(20px);
      }

      &-flag {
        font: $typography-body;
        font-weight: $font-weight-1;
        letter-spacing: rem(-0.4px);
        color: $color-text-body;
        display: inline-block;
        height: rem(24px);
      }

      &-issue {
        display: flex;
        align-items: flex-start;
        background-color: $color-background-default;
        margin-top: $spacing-3;
        color: $color-text-body;
        font-feature-settings:
          'clig' off,
          'liga' off;

        font-size: rem(16px);
        font-style: normal;
        font-weight: $font-weight-1;
        line-height: rem(24px);
        letter-spacing: rem(-0.4px);
        padding: $spacing-4 $spacing-5;
        border-radius: $border-radius-3;

        .recomDescContainer {
          display: flex;
          align-items: flex-start;

          .recoDescIcon {
            margin-right: $spacing-3;
          }
        }
      }
    }

    &-dataRetenion {
      display: flex;
      flex-direction: column;
      margin-top: $spacing-6;

      h3 {
        display: contents;
        font: $typography-body-s;
        font-weight: $font-weight-1;
        letter-spacing: rem(-0.2px);
        color: $color-text-body;
        height: rem(20px);
        // display: inline-block;
      }

      &-flag {
        font: $typography-body;
        font-weight: $font-weight-1;
        letter-spacing: rem(-0.4px);
        color: $color-text-body;
        // display: inline-block;
        height: rem(24px);
      }

      &-issue {
        display: flex;
        align-items: flex-start;
        background-color: $color-background-default;
        margin-top: $spacing-3;
        color: $color-text-body;
        font-feature-settings:
          'clig' off,
          'liga' off;

        font-size: rem(16px);
        font-style: normal;
        font-weight: $font-weight-1;
        line-height: rem(24px);
        letter-spacing: rem(-0.4px);
        padding: $spacing-4 $spacing-5;
        border-radius: $border-radius-3;

        .recomDescContainer {
          display: flex;
          align-items: flex-start;

          .recoDescIcon {
            margin-right: $spacing-3;
          }
        }
      }
    }
  }

  &-noScans {
    margin-top: $spacing-7 !important;
  }

  &-col9Style {
  }

  &-col9To12Style {
    display: none !important;
  }

  &-col3Style {
  }

  &-col3To12Style {
    display: none !important;
  }

  &-s-t-scanStatus-icon {
    display: flex;
    align-items: center;
    span:first-child {
      font-size: $spacing-4;
    }

    span:last-child {
      margin-left: $spacing-2;
    }
  }

  @media screen and (max-width: 1048px) {
    &-col9Style {
      display: none !important;
    }

    &-col9To12Style {
      display: block !important;
    }

    &-col3Style {
      display: none !important;
    }

    &-col3To12Style {
      display: block !important;
    }
  }

  &-accessRequirements-icon {
    font-size: $spacing-7;
  }
}

.outletcontainer:has(.settingsPage) {
  height: 100vh;
}
.settingsPage-noScans{
  .home-projectContent-noProducts {
    max-width: unset;
  }
}

[data-mode='dark'] {
  .settingsPage-basicInfo-privatePart-issue {
    background-color: $color-background-hover-selected;
  }
  .settingsPage-basicInfo-dataRetenion-issue {
    background-color: $color-background-hover-selected;
  }
  .settingsPage-basicInfoModal-modalBody-privacyPart-desc,
  .settingsPage-basicInfoModal-modalBody-dataRetentionPart-desc,
  .editOtherSettingsModal-body-autoScansPart-nextScanTime-issue,
  .editOtherSettingsModal-body-autoScansPart-nextScanTime-issue {
    background-color: $color-background-hover-selected;
  }
  .settingsPage-modalBody-issue {
    background-color: $color-background-hover-selected;
  }
}