@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';
.issueFiltersDashboardContainer {
  width: 100%;

  .cntDisabled {
    opacity: 0.32;
    cursor: not-allowed !important;
  }

  .titlePart:hover {
    background-color: $color-background-hover-selected;
  }
  .titlePart,
  .noIssueTitlePart {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    border-radius: $border-radius-2;
    padding: $spacing-2 $spacing-3;

    .arrowIcon {
      cursor: pointer;
      transition: transform 0.3s;
    }

    .iconRote {
      transform: rotate(180deg);
      .visually-hidden {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    }

    span:first-child {
      font-weight: $font-weight-2;
      font-size: rem(16px);
      line-height: rem(24px);
      letter-spacing: rem(-0.4px);
      color: $color-text-heading;
    }
  }

  .issuesPart {
    display: flex;
    flex-direction: column;

    .issueArea {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: rem(5px);
      padding: $spacing-2 $spacing-3;

      .nameText,
      .countPart {
        font-weight: $font-weight-1;
        font-size: rem(16px);
        line-height: rem(24px);
        letter-spacing: rem(-0.4px);
      }
      .nameText {
        color: $color-text-body;
      }
      .countPart {
        color: $color-text-light;
        padding-right: rem(7px);
      }
    }
  }

  .toggleSssuesPart {
    display: none;
  }
}
