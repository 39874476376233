@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';

.avatarNew {
  &-assignedUserPart {
    margin-top: $spacing-6;
    max-height: rem(336px);
    overflow-y: auto;
    padding: $spacing-3;

    &-usrContainer {
      height: rem(64px);
      display: flex;
      align-items: center;

      &-nickName {
        margin-right: $spacing-4;
      }
      &-nameEmail {
        display: flex;
        flex-direction: column;
        justify-content: center;

        span:first-child {
          font: $typography-body;
          font-weight: $font-weight-2;
          letter-spacing: rem(-0.4px);
          color: $color-text-body;
        }

        span:last-child {
          font: $typography-body;
          font-weight: $font-weight-1;
          letter-spacing: rem(-0.4px);
          color: $color-text-light;
        }
      }
    }
  }
}
