@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';

.home {
  margin-top: rem(64px);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $color-background-default;

  &-content {
    // margin-top: $spacing-6;
    margin-top: $spacing-5;
    padding-bottom: $spacing-6;
    @media screen and (min-width: 1440px) {
      width: rem(1080px);
    }
    width: 100%;
    // @media screen and (min-width: 1025px) and (max-width: 1440px) {
    //   width: calc(100% - rem(32px));
    // }
    // @media screen and (max-width: 768px) {
    //   width: 100%;
    // }
  }

  &-top {
    display: flex;
    align-items: center;

    &-labelTextArea {
      display: flex;
      flex-direction: column;
      
      h1 {
        font: $typography-heading-l;
        font-weight: $font-weight-1 !important;
        letter-spacing: rem(-0.4px);

        span:last-child {
          font-weight: $font-weight-3;
        }
      }

      &-span {
        font: $typography-heading-s;
        letter-spacing: rem(-0.4px);
        font-weight: $font-weight-1 !important;
        color: $color-text-light;
        margin-top: $spacing-3;
      }
    }

    &-btnArea {
      display: flex;
      justify-content: flex-end;

      button {
        width: rem(169px);
      }
    }
  }

  &-center {
    margin-top: rem(32px) !important;
    .ap-search-container {
      padding-left: 0px !important;
      padding-right: 0px !important;
      margin-bottom: rem(8px);
    }
    &-search {
      // width: rem(316px) !important;
      width: calc(100% - rem(12px)) !important;
      @media screen and (max-width: 1023px) {
        width: calc(100vw - rem(328px)) !important;
      }
      @media screen and (max-width: 768px) {
        width: calc(100vw - rem(328px)) !important;
      }
    }
  }

  &-projectContent {
    display: grid;
    gap: $spacing-5;
    grid-template-columns: repeat(3, 1fr);
    &.noSearchedProducts {
      grid-template-columns: unset;
    }
    &.noProductsCls {
      grid-template-columns: unset;
    }
    &-loading {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    // &.noProductsCls {
    //   margin-top: $spacing-4 !important;
    // }

    // .projectDashboardContainer {
    //   margin: $spacing-5 $typography-text-size-6 $spacing-5 0;
    // }

    @media screen and (max-width: 1023px) {
      .projectDashboardContainer {
        width: calc(100vw - rem(328px)) !important;;
      }
    }
    @media screen and (max-width: 768px) {
      .projectDashboardContainer {
        width: calc(100vw - rem(328px)) !important;;
      }
    }
    @media screen and (min-width: 1024px) and (max-width: 1439px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      grid-template-columns: repeat(1, 1fr);
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }

    &-noProducts {
      width: 100%;
      height: 100%;
      padding: $spacing-8 rem(72px);
      border-radius: $border-radius-3;
      background-color: $color-background-container;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: $spacing-5;
      box-shadow: $box-shadow-1;
      max-width: calc(100vw - rem(328px));;
      &-imgArea {
      }

      &-titleArea {
        font: $typography-heading-m;
        letter-spacing: rem(-0.4px);
        margin-top: $spacing-4;
      }

      &-descArea {
        font: $typography-body;
        font-weight: $font-weight-1 !important;
        margin-top: $spacing-3;
        color: $color-text-body;
        text-align: center;
      }

      &-btnArea {
        margin-top: $spacing-5;
      }
    }
  }

  &-footer {
    margin-bottom: $spacing-4;
  }

  &-modalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &-descArea {
      margin-left: $spacing-3;
      span {
        position: relative;
        font-size: rem(14px);
        font-weight: $font-weight-1;
        line-height: rem(16px);
        letter-spacing: rem(-0.4px);
        color: $color-text-light;
      }
      span::before {
        content: '';
        background-color: #c52b19;
        position: absolute;
        left: rem(-8px);
        top: $spacing-3;
        display: inline-block;
        width: $spacing-2;
        height: $spacing-2;
        border-radius: $spacing-1;
      }
    }

    &-btnArea {
      display: flex;
      button:nth-child(1) {
        margin-right: $spacing-3;
      }
      .sr-only{
        opacity: 0;
        width: 0;
        height: 0;
        overflow: hidden; 
      }
    }
  }

  &-modalBody {
    &-productName {
      input::-webkit-input-placeholder {
        font-size: rem(16px);
        font-weight: $font-weight-1;
        line-height: rem(20px);
        letter-spacing: rem(-0.4px);
        color: $color-text-light;
      }
      input::-moz-placeholder {
        /* Firefox 19+ */
        font-size: rem(16px);
        font-weight: $font-weight-1;
        line-height: rem(20px);
        letter-spacing: rem(-0.4px);
        color: $color-text-light;
      }
      input:-ms-input-placeholder {
        /* IE 10+ */
        font-size: rem(16px);
        font-weight: $font-weight-1;
        line-height: rem(20px);
        letter-spacing: rem(-0.4px);
        color: $color-text-light;
      }
      input:-moz-placeholder {
        /* Firefox 18- */
        font-size: rem(16px);
        font-weight: $font-weight-1;
        line-height: rem(20px);
        letter-spacing: rem(-0.4px);
        color: $color-text-light;
      }

      &-inputError {
        font-size: rem(12px);
        font-weight: $font-weight-1;
        line-height: rem(12px);
        letter-spacing: rem(-0.2px);
        color: $color-text-error;
        margin-top: $spacing-3;
        margin-left: $spacing-3;
      }
    }

    &-privacyPart {
      margin-top: $spacing-6;

      &-title {
        font: $typography-body;
        letter-spacing: rem(-0.4px);
        font-weight: $font-weight-2 !important;
      }

      &-toggle {
        margin-top: $spacing-3;

        &-switch {
          cursor: pointer;
          width: rem(150px);
        }

        .ap-switch-label {
          font-weight: 400 !important;
        }
      }

      &-desc {
        display: flex;
        align-items: center;
        margin-top: $spacing-4;
        background-color: $color-background-default;
        border-radius: $spacing-3;
        height: $spacing-8;
        padding: $spacing-3 $spacing-4;

        span:first-child {
          margin-right: $spacing-3;
        }
      }
    }

    &-addTeamerPart {
      margin-top: $spacing-6;

      &-label {
        font: $typography-body;
        letter-spacing: rem(-0.4px);
        font-weight: $font-weight-2;
      }
      &-search {
        &-container {
          height: rem(64px);

          .ap-search-container {
            padding-left: 0;
            padding-right: 0;
            z-index: 9;

            &.show {
              padding-left: $spacing-3;
              padding-right: $spacing-3;
            }

            input::-webkit-input-placeholder {
              font-size: rem(16px);
              font-weight: $font-weight-1;
              line-height: rem(20px);
              letter-spacing: rem(-0.4px);
              color: $color-text-light;
            }
            input::-moz-placeholder {
              /* Firefox 19+ */
              font-size: rem(16px);
              font-weight: $font-weight-1;
              line-height: rem(20px);
              letter-spacing: rem(-0.4px);
              color: $color-text-light;
            }
            input:-ms-input-placeholder {
              /* IE 10+ */
              font-size: rem(16px);
              font-weight: $font-weight-1;
              line-height: rem(20px);
              letter-spacing: rem(-0.4px);
              color: $color-text-light;
            }
            input:-moz-placeholder {
              /* Firefox 18- */
              font-size: rem(16px);
              font-weight: $font-weight-1;
              line-height: rem(20px);
              letter-spacing: rem(-0.4px);
              color: $color-text-light;
            }

            .ap-search-list {
              max-height: rem(200px);
              overflow-y: auto;
            }
          }
        }

        &-divider {
          opacity: 0.32;
          background-color: $color-text-heading;
          width: 100%;
          height: rem(1px);
          margin-top: $spacing-5;
        }

        &-contentContainer {
          max-height: rem(256px);
          overflow-y: auto;
        }
      }
    }
  }
}

.ap-container.itemFooterContent.home-page{
  .home{
    margin-top: 0;
  }
}

// .ap-container:has(.home-content) .row > * {
  // &:not(.home-footer) {
  //   width:min-content;
  // }
  // .loading-content {
  //   width: 100%;
  // }
// }

.ap-container:has(.home-content) .row > * {
  &.home-top {
    width:100%;
    // @media screen and (min-width: 1440px) {
    //   min-width: cal c;
    // }
  }
}

.ap-container:has(.home-content) .row > * {
  &.noSearchedProducts {
    width:100%;
    // margin-left: rem(16px);
    margin-top: rem(-16px);
  }
  &.noProductsCls{
    width:100%;
    // margin-left: rem(16px);
    margin-top: rem(-16px);
  }
}
// .ap-container.home:not(.getting-started) {
//   display: flex;
//   align-items: center;
// }
.ap-container.home .row > *:not(.footer-container) {
  padding-left: 0;
  padding-right: 0;
}
// .ap-container.home {
  // .home-content:not(.noSearchedProducts):not(.smallerWidth):not(.loading-content) {
  //   width: min-content;
  // }
//   .home-content.loading-content {
//     width: 100%;
//   }
// }
.ap-container .row > * {
  &.home-projectContent.noSearchedProducts {
    padding-left: 0;
  }
  &.home-projectContent.noProductsCls {
    padding-left: 0;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .ap-container.home .row .home-center .col-4 {
    width: 50%;
  }
  .ap-container.home-page {
    width: calc(100vw - rem(280px)) !important;
    // padding-left: unset;
    // padding-right: unset;
  }
}
.home-projectContent:has(.loading-container.home-projectContent-loading) {
  display: flex;
  align-items: center;
  width: 100%!important;
}
// @media screen and (min-width: 1024px) and (max-width: 1439px) {
//   .ap-container:has(.home-content) .row {
//     .home-top,.home-projectContent {
//       max-width: rem(672px)!important;
//     }
//   }
// }
// @media screen and (max-width: 1024px) {
//   .ap-container:has(.home-content) .row {
//     .home-top,.home-projectContent {
//       max-width: rem(672px)!important;
//     }
//   }
// }
.ap-container:has(.home-content) .row {
  .home-top,.home-projectContent {
    width: 100%;
    // max-width: calc(100vw - rem(328px));
    @media screen and (min-width: 1440px) {
      max-width: rem(1080px);
    }
  }

  @media screen and (max-width: 1023px) { 
    .home-top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .col-8,.col-4{
        width: 100%;
        .home-top-btnArea {
          justify-content: flex-start;
          margin-top: rem(32px);
          margin-left: rem(16px);
          transform: translateX(rem(-16px));
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ap-container.home:not(.getting-started) {
    padding-left: 0!important;
  }
}
@media screen and (max-width: 1439px) {
  h1 {
    display: flex;
    flex-direction: column;
  }
}

// @media screen and (min-width: 1440px) {
//   .ap-container:has(.home-content) .row > * {
//     &.noSearchedProducts {
//         .home-projectContent-noProducts{
//           max-width: rem(1048px);
//         }
//       }
//       &.noProductsCls {
//         .home-projectContent-noProducts{
//           max-width: rem(1048px);
//         }
//       }
//     }
//   }
// @media screen and (max-width: 1439px) {
//   .ap-container:has(.home-content) .row > * {
//     &.noSearchedProducts {
//         .home-projectContent-noProducts{
//           max-width: rem(650px);
//           margin-left: rem(-6px);
//         }
//       }
//       &.noProductsCls {
//         .home-projectContent-noProducts{
//           max-width: rem(650px);
//           margin-left: rem(-6px);
//         }
//       }
//     }
//   }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .ap-container:has(.home-content) .row > * {
      &.noSearchedProducts {
          padding-right: 0;
          .home-projectContent-noProducts{
            max-width: calc(100vw - rem(328px));
          }
        }
        &.noProductsCls {
          padding-right: 0;
          .home-projectContent-noProducts{
            max-width: calc(100vw - rem(328px));
          }
        }
      }
    }
  @media screen and (max-width: 768px) {
    .ap-container:has(.home-content) .row > * {
      &.noSearchedProducts {
          margin-left: 0;
          .home-projectContent-noProducts{
            max-width: rem(440px);
            // margin-left: rem(12px);
          }
        }
        &.noProductsCls {
          margin-left: 0;
          .home-projectContent-noProducts {
            max-width: rem(440px);
            // margin-left: rem(12px);
          }
        }
      }
    }

.ap-container.home-page {
  @media screen and (min-width: 1441px) {
    margin-left: auto;
    margin-right: auto;
  }
  max-width: unset;
  width: unset;
  // @media screen and (min-width: 1800px) {
  //   padding-left: 0;
  //   padding-right: 0;
  // }
 .ap-container.home {
  padding-left: 0;
  padding-right: 0;
  max-width: unset;
  .home-content:has(.home-content-footer){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    padding-bottom: 0;
  }
  .row:has(.home-footer){
    width: 100%;
    margin-left: 0;
  }
 }
}
.ap-container .row:has(.home-projectContent),
.ap-container .row:has(.home-center),
.ap-container .row:has(.home-top) {
  margin-left: 0;
  margin-right: 0;
}
[data-mode='dark'] {
  .home-modalBody-privacyPart-desc {
    background-color: $color-background-hover-selected;
  }
}



