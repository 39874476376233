@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';

.floating-menu-container {
    position: fixed;
    z-index: 6;
    top: rem(96px);
    transition: all 0.5s;
    &.open {
      left: rem(20px);
    }

    .floating-menu-list-container {
      top: rem(80px);
      border-radius: $border-radius-3;
      padding: $spacing-2;
      background-color: $color-background-alt;
      position: fixed;
      box-shadow: var(--box-shadow-2);
      display: flex;
      flex-direction: column;
      gap: var(--spacing-3);
    }

    .floating-menu-icon-container {
      all: unset;
      border-radius: $border-radius-2;
      padding: $spacing-4;
      font-size: $typography-text-size-5;
      display: flex;
      cursor: pointer;
      position: relative;
      &.ap-button.ap-button-icon {
        padding: rem(12px);
        color: $color-text-body;
        .Appkit4-icon{
            margin-right: 0;
        }
        &:focus:not(.keyboard-focus) {
            outline: auto rem(5px) -webkit-focus-ring-color!important;
            box-shadow: none!important;
        }
        &.selected {
            background-color: $color-background-default!important;
            color: $color-text-primary!important;
        }
        &:not(.in-loading):hover {
          background-color: $color-background-default!important;
        }
      }
      
      .icon-arrow-increase-small-outline {
        position: absolute;
        top: rem(-4px);
        right: 0;
        width: rem(12px);
        height: rem(12px);
        &::before {
          font-size: rem(12px);
        }
      }
    }
    .bottom-icon{
      width: rem(56px);
      height: rem(56px);
      border-radius: $border-radius-3;
      padding: $spacing-2;
      background-color: $color-background-alt;
      color: $data-rose;
      position: fixed;
      bottom: rem(20px);
      box-shadow: var(--box-shadow-2);
    }
  }

  [data-mode='dark'] {
    .floating-menu-list-container,.bottom-icon {
      background-color: $color-background-container;
    }
    .floating-menu-icon-container {
      &.ap-button.ap-button-icon {
        &.selected {
          background-color: $color-background-selected!important;
        }
      }
    }
    .floating-menu-container .floating-menu-icon-container.ap-button.ap-button-icon:not(.in-loading):hover {
      background-color: $color-background-selected!important;
    }
  }