@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';

.ap-feedback-issue-detail-comment {
  display: flex;
  position: relative;
  max-width: calc(100% - rem(60px));
  &:not(:last-child) {
    padding-bottom: $spacing-8;
  }

  &:last-child {
    .comment-left .comment-timeline {
      display: none;
    }
  }

  .comment-left {
    .comment-timeline {
      width: $spacing-1;
      border-radius: $border-radius-1;
      background-color: $color-text-disabled;
      position: absolute;
      left: $spacing-6;
      top: rem(44px);
      transform: translateX(-50%);
      height: calc(100% - $spacing-8);
    }

    .avatar-image {
      width: rem(40px);
      height: rem(40px);
      border-radius: 50%;
    }
  }

  .comment-right {
    margin-left: $spacing-4;
    max-width: 100%;
    .comment-content {
      border-radius: $border-radius-3;
      background-color: $color-background-hover;
      width: 100%;
      padding: $spacing-4;

      &.closed {
        background-color: $states-error-01;
      }

      .comment-right-content {
        display: flex;
        .historyCommentCodeContainer {
          font-weight: $font-weight-1;
          line-height: $spacing-7 !important;

          overflow: auto;
          pre[class*='language-'] {
            background: unset;
          }

          img {
            width: 100%;
          }
        }

        &-open-iconColor {
          color: $states-success-04;
        }
        &-close-iconColor {
          color: $primary-pink-05;
          // background-color: rgba(217, 57, 84, 0.2);
        }

        & span:last-child {
          font-weight: $font-weight-1;
          font-size: $typography-body;
          line-height: $spacing-7;
          letter-spacing: rem(-0.4px);
          color: $color-text-body;
        }

        & img {
          margin-top: $spacing-4;
        }
      }
    }

    .comment-date {
      color: $color-text-light;
      font-size: $typography-text-size-2;
      letter-spacing: $letter-spacing-1;
      line-height: $typography-line-height-3;
      margin-top: $spacing-3;
    }
  }
}

[data-mode='dark'] {
  .comment-right {
    .comment-content {
      &.closed {
        background-color: rgba(217, 57, 84, 0.2);
      }
    }
  }

  .historyCommentCodeContainer {
    pre[class*='language-'] {
      background-color: #303030 !important;
    }

    code[class*='language-'] {
      color: #d1d1d1 !important;
      text-shadow: none !important;
    }
  }
}
