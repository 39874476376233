@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';
.header-part-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: rem(64px);
  position: fixed;
  width: 100%;
  z-index: 99;

  .headerComCalss {
    padding: $spacing-6 $spacing-4 $spacing-6 $spacing-6;
  }

  .left {
    display: flex;

    .productName,
    .main-name {
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-size: rem(16px);
      font-style: normal;
      font-weight: $font-weight-1;
      line-height: rem(24px);
      letter-spacing: rem(-0.4px);
    }

    .productName {
      display: flex;
      align-items: center;
      padding-left: $spacing-3;
    }
    .main-name {
      padding: 0px $spacing-3 0px 0px;
      display: flex;

      div {
        margin-left: $spacing-3;
      }
    }

    .divider {
      opacity: 0.32;
      background-color: $color-text-heading;
      width: rem(1px);
    }
  }

  .span-title {
    width: 0;
    height: 0;
    display: block;
    overflow: hidden;
    position: relative;
    left: -rem(300px);
  }

  .skip-link {
    width: rem(193px);
    height: $height-5;
    border-radius: $border-radius-2;
    letter-spacing: $letter-spacing-1;
    font-weight: $font-weight-2;
    font-size: $typography-text-size-3;
    color: $neutral-01;
    left: rem(300px);
    position: absolute;
    top: rem(12px);
    z-index: -1;
    text-align: center;
    line-height: $typography-line-height-40;
    opacity: 0;
    background-color: none;

    &.skip-link-pc:focus {
      box-shadow: 0 0 0 rem(2px) $data-blue !important;
      background-color: $neutral-01;
      padding: rem(1px);
      outline: none;

      span {
        position: absolute;
        width: rem(189px);
        top: $spacing-1;
        left: $spacing-1;
        background-color: $color-background-primary;
        line-height: $typography-line-height-6;
        border-radius: $border-radius-2;
      }

      &:hover {
        padding: $spacing-1;
        background-color: $neutral-01 !important;

        span {
          background-color: $color-background-primary;
        }
      }
    }

    @media (min-width: rem(1025px)) {
      &#skip-link2 {
        display: none !important;
      }
    }

    @media (max-width: rem(1024px)) {
      &#skip-link {
        display: none !important;
      }
    }

    &:focus {
      z-index: 999;
      background-color: $primary-orange-05;
      opacity: 1;
    }

    &:hover {
      text-decoration: none;
      color: $color-text-secondary;
      background-color: #586894;
      cursor: pointer;
    }
  }
}

.header-preferenceModal-modalBody {
  &-radioPart {
    h2 {
      font: $typography-body;
      font-weight: $font-weight-2;
      color: $color-text-heading;
      letter-spacing: rem(-0.4px);
    }

    &-radioGroup {
      margin-top: $spacing-3;

      .ap-radio-container {
        label {
          font-weight: $font-weight-1;
        }
      }

      .ap-radio-container:last-child {
        margin-top: $spacing-4;
      }
    }
  }

  &-togglePart {
    margin-top: $spacing-6;
    &-switch {
      margin-top: $spacing-3;
      h2 {
        font: $typography-body;
        font-weight: $font-weight-2;
        color: $color-text-heading;
        letter-spacing: rem(-0.4px);
      }
    }
  }
}
.header-preferenceModal-modalFooter {
}

.header-part-container-right {
  display: flex;
  .header-part-container-faq {
    width: rem(131px);
    padding: $spacing-3;
    margin-right: $spacing-3;
    display: flex;
    flex-direction: row-reverse;

    .header-part-container-faq-content {
      display: flex;
      align-items: center;

      span:last-child {
        cursor: pointer;
        margin-left: $spacing-2;
        font: $typography-body;
        font-weight: $font-weight-1;
        color: $color-text-body;
      }
    }
  }
}

.Appkit4-icon.icon-logout-outline:before {
  content: "\e8ad";
}

#tooltipSubscription{
  .ap-tooltip-reactContent{
    width: rem(253px);
  }
}

.header-sr-only{
  opacity: 0;
  width: 0;
  height: 0;
}

.ap-pattern-confirmation-logout {
  width: rem(540px);
  height: rem(202px);

  .ap-modal-body {
    margin: rem(8px) $spacing-6 $spacing-6 $spacing-6;
    padding: 0;

    .ap-pattern-confirmation-message {
      height: rem(50px);

      .bold-text {
        font-weight: $font-weight-2;
      }
    }
  }

  .ap-modal-footer {
    padding-top: rem(8px);

    .ap-modal-footer-customize {
      align-items: flex-end;
      .logout-btn-group {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
}