@import 'node_modules/sass-rem/_rem.scss';
@import '../../styles/scss/variables';

.issueDetailContainer {
  .breadcrumbPart {
    // margin-top: $spacing-4;
    margin-top: $spacing-5;
    display: flex;
    justify-content: space-between;

    .tagStyle {
      background-color: $color-background-default !important;
      border: rem(1px) solid $color-text-body !important;
      font-weight: $font-weight-1 !important;
      font-size: rem(14px) !important;
      color: $color-text-body;
    }
    .ap-tag.ap-tag-default:not(.ap-tag-disabled):not(.ap-tag-outlined) {
      color: $color-text-body;
    }
    .ap-tag .ap-tag-label {
      margin-right: 0;
    }
  }
  a {
    &:hover {
        text-decoration: none!important;
    }
  }
  .detail-dashboardPart {
    margin-top: $spacing-6 !important;
    .tagPart{
      width: 30%;
    }
  }

  .detailOccurSuggestPart {
    margin-top: $spacing-4 !important;

    .tabsContentPart {
      width: 100%;
      border-radius: $border-radius-3;
      background-color: $color-background-container;
      box-shadow: $box-shadow-1;
      padding: $spacing-6;

      .tabsContainer {
        border-bottom: rem(2px) solid $color-background-hover-selected;

        .ap-tabset-container {
          .ap-tabset-toggle-wrapper {
            .ap-tabset-underline {
              display: none;
            }

            .ap-tabset-toggle {
              .ap-tabset-toggle-underline {
                bottom: -2px;
              }
            }
          }
        }
      }

      .tab-details {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-top: $spacing-4;

        .mtp {
          margin-top: $spacing-4;
        }

        .tab-details-common {
          padding: $spacing-4;

          .tab-details-common-header {
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            .icon-commom {
              transition: 0.3s;
            }

            .iconRotate {
              transform: rotate(180deg);
            }

            & span:first-child {
              font-weight: $font-weight-2;
              font-size: rem(16px);
              line-height: $spacing-7;
              letter-spacing: rem(-0.4px);
              color: $color-text-heading;
            }

            & span:last-child {
              width: rem(16px);
              height: rem(16px);
            }
          }

          .tab-details-common-content-hide {
            display: none;
          }

          .tab-details-common-content {
            margin-top: $spacing-5;

            .tab-details-description {
              font-size: rem(16px);
              font-weight: $font-weight-1;
              letter-spacing: rem(-0.4px);
              color: $color-text-body;

              img {
                width: 100%;
              }
            }

            .no-code-snippet, .no-description {
              color: $color-text-light;
            }
            .tab-suggestions-lightPart {
              padding: $spacing-5;
              background-color: $color-background-container-alt;
              border-radius: rem(8px);
              display: flex;
              align-items: center;
              font-size: rem(16px);
              line-height: rem(24px);
              height: rem(48px);
              @media screen and (max-width: 995px) {
                height: unset;
              }
              display: flex;
              align-items: center;
              width: 100%;
              color: $color-text-body;
              .lightIcon {
                width: $spacing-5;
                height: $spacing-7;
                line-height: $spacing-7;
              }
            }

            .tab-details-scopeImpactPart {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: $spacing-2;

              .tab-details-scopeImpactPart-conformanceLevelPart,
              .tab-details-scopeImpactPart-wcagCriteriaNotMetPart,
              .tab-details-scopeImpactPart-issueScopePart {
                width: 33.33%;
                display: flex;
                flex-direction: column;
              }

              .tab-details-scopeImpactPart-conformanceLevelPart,
              .tab-details-scopeImpactPart-issueScopePart,
              .tab-details-scopeImpactPart-wcagCriteriaNotMetPart {
                & span:first-child {
                  font-weight: $font-weight-1;
                  font-size: rem(14px);
                  line-height: $spacing-6;
                  letter-spacing: rem(-0.2px);
                  color: $color-text-light;
                }

                & span:last-child {
                  font-weight: $font-weight-1;
                  font-size: rem(16px);
                  line-height: $spacing-7;
                  letter-spacing: rem(-0.4px);
                  color: $color-text-body;
                }
              }

              .tab-details-scopeImpactPart-conformanceLevelPart {
                margin-top: $spacing-2;

                &-tipPart {
                  display: flex;

                  span:first-child {
                    font: $typography-body;
                    font-weight: $font-weight-1;
                    color: $color-text-body;
                    margin-right: $spacing-2;
                  }
                }
              }

              .tab-details-scopeImpactPart-wcagCriteriaNotMetPart {
                & a {
                  font-weight: $font-weight-1;
                  font-size: rem(16px);
                  line-height: $spacing-7;
                  letter-spacing: rem(-0.4px);
                  text-decoration: underline;
                  color: $color-text-link-primary;
                }
              }
            }
          }
        }
      }

      .tab-occurences {
        margin-top: $spacing-4;
        padding: $spacing-4;

        .tab-occurences-header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .icon-commom {
            transition: 0.3s;
          }

          .iconRotate {
            transform: rotate(180deg);
          }

          & span:first-child {
            font-weight: $font-weight-2;
            font-size: rem(16px);
            line-height: $spacing-7;
            letter-spacing: rem(-0.4px);
            color: $color-text-heading;
          }
        }

        .tab-occurences-content-hide {
          display: none;
        }

        .tab-occurences-content {
          overflow-y: auto;
          max-height: rem(580px);
          margin-right: rem(6px);
          padding-right: rem(18px);

          &.overflow {
            overflow-y: auto;
          }

          .link-container:first-child {
            margin-top: $spacing-4;
          }

          .link-container {
            margin-top: $spacing-2;
          }

          & a {
            font-weight: $font-weight-1;
            font-size: rem(16px);
            line-height: $spacing-7;
            letter-spacing: rem(-0.4px);
            color: $color-text-primary;
            text-decoration: underline;
          }

          & div {
            padding: $spacing-3;
            // height: rem(40px);
          }

          & div:nth-child(even) {
            background-color: $color-background-default;
          }
        }
      }

      .tab-suggestions {
        // padding: $spacing-4;
        // margin-top: $spacing-4;
        .tab-suggestions-error {
          display: flex;
          justify-content: center;
          align-items: center;
          .Appkit4-icon.icon-alert-outline {
            color: $color-background-danger;
          }
          a {
              font-weight: $font-weight-1;
              font-size: 1rem;
              line-height: $spacing-7;
              letter-spacing: -0.025rem;
              text-decoration: underline;
              color: $color-text-link-primary;
              cursor: pointer;
          }
        }

        .tab-suggestions-header {
          & span {
            font-size: $typography-body;
            font-weight: $font-weight-2;
            line-height: $spacing-7;
            letter-spacing: rem(-0.4px);
            color: $color-text-heading;
            line-height: rem(16px);
          }
        }

        .tab-suggestions-desc {
          margin-top: $spacing-4;

          & span {
            font-size: $typography-body;
            font-weight: $font-weight-1;
            line-height: $spacing-7;
            letter-spacing: rem(-0.4px);
            color: $color-text-body;
          }
        }

        .tab-suggestions-sampleCode {
          margin-top: $spacing-4;
        }

        .tab-suggestions-lightPart {
          // margin-top: $spacing-4;
          padding: $spacing-3 $spacing-3;
          background-color: $color-background-container-alt;
          border-radius: rem(4px);

          display: flex;
          align-items: center;
          font-size: rem(12px);
          line-height: rem(14px);
          height: rem(22px);
          @media screen and (max-width: 995px) {
            height: unset;
          }
          display: flex;
          align-items: center;
          width: fit-content;
          color: $color-text-light;
          a {
            text-decoration: underline;
            color: inherit;
          }
          .lightIcon {
            width: $spacing-5;
            height: $spacing-7;
            line-height: $spacing-7;
          }
        }
      }
    }
  }

  .historyPart {
    margin-top: $spacing-4 !important;

    .historyPart-content {
      padding: $spacing-6;
      border-radius: $border-radius-3;
      background-color: $color-background-container;
      box-shadow: $box-shadow-1;
    }
  }

  .commentsPart {
    margin-top: $spacing-4 !important;

    .commentsPart-content {
      padding: $spacing-6;
      border-radius: $border-radius-3;
      background-color: $color-background-container;
      box-shadow: $box-shadow-1;
    }
  }

  .relatedCheckPoint {
    span {
      letter-spacing: rem(-0.4px);
      font: $typography-body;
      font-weight: $font-weight-1;
      color: $color-text-body !important;
    }
  }

  &-allImpactedStyle {
    font-weight: $font-weight-1 !important;
    font: $typography-body;
    color: $color-text-body;
    display: inline-block;
    margin-top: $spacing-4;
  }
}

.outletcontainer:has(.issueDetailContainer),
.outletcontainer:has(.ap-issue-not-found) {
  height: 100vh;
}
